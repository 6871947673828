import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";

import { NodeContainer } from "@/domains/nodes/components/cards/components/NodeContainer";
import { NodeListItem } from "@/domains/nodes/components/lists/components/NodeListItem";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { handleBottomStyle, handleTopStyle } from "@/domains/projects/components/tree/config/reactFlow";

export const TreeListItem: React.FC<NodeProps<NodeModel>> = ({ data }) => {
	const node = data;

	const nodeId = node.id;

	return (
		<div data-id={nodeId} className="draggable">
			<Handle type="target" id={`target-${nodeId}`} position={Position.Top} isConnectable={true} style={handleTopStyle} />
			<NodeContainer node={node} variant="highlight">
				<ItemWrapper>
					<NodeListItem node={node} />
				</ItemWrapper>
			</NodeContainer>
			<Handle
				type="source"
				id={`source-${nodeId}`}
				position={Position.Bottom}
				isConnectable={true}
				style={handleBottomStyle}
			/>
		</div>
	);
};

const ItemWrapper = styled.div`
	width: 500px;
	padding: 8px 8px 8px 12px;
	background: var(--color-body);
	border-radius: 8px;
`;
