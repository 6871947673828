import { createStore } from "zustand";

import { EventModel } from "@/domains/nodes/models/eventsModel";

type State = {
	events: Record<string, EventModel>;
};

type Actions = {
	setEvents(events: State["events"]): void;
};

export type EventsStore = State & Actions;

export const eventsStore = createStore<EventsStore>((set) => ({
	events: {},
	setEvents: (events) => set({ events }),
}));
