import { Navigate, Route, Routes } from "react-router-dom";

import HomePage from "../../landing/home/Home";
import { lazyWithRetry } from "../utils/lazyWithRetry";
import routes from "./routes";

const AppSumoPage = lazyWithRetry(() => import("../../modules/authentication/AppSumoPage"));
const EmailActionsPage = lazyWithRetry(() => import("../../modules/authentication/EmailActionsPage"));
const ForgotPasswordPage = lazyWithRetry(() => import("../../modules/authentication/ForgotPasswordPage"));
const LoginPage = lazyWithRetry(() => import("../../modules/authentication/LoginPage"));
const PublicPlansPage = lazyWithRetry(() => import("../../landing/pricing/PlanPagePublic"));
const PrivacyPage = lazyWithRetry(() => import("../../landing/legal/PrivacyPage"));
const PromotionPage = lazyWithRetry(() => import("../../landing/pricing/PromotionPage"));
const PublicBoardPage = lazyWithRetry(() => import("../../modules/board/public/PublicBoard"));
const PublicRoadmapPage = lazyWithRetry(() => import("../../domains/projects/components/roadmap/PublicRoadmap"));
const SignUpPage = lazyWithRetry(() => import("../../modules/authentication/SignUpPage"));
const TermsPage = lazyWithRetry(() => import("../../landing/legal/TermsPage"));
const WorkflowsPage = lazyWithRetry(() => import("../../landing/workflows/Workflows"));
const Error404 = lazyWithRetry(() => import("../../modules/errors/Error404"));

const authRoutes: string[] = [
	routes.account,
	routes.boardSettings,
	routes.members,
	routes.profile,
	routes.plans,
	routes.welcome,
	routes.workspace,
];

export const RoutesPublic = () => {
	return (
		<Routes>
			<Route index element={<HomePage />} />
			<Route path={routes.appSumo} element={<AppSumoPage />} />
			<Route path={routes.board} element={<PublicBoardPage />} />
			<Route path={routes.boardEmbed} element={<PublicBoardPage />} />
			<Route path={routes.emailActions} element={<EmailActionsPage />} />
			<Route path={routes.forgotPassword} element={<ForgotPasswordPage />} />
			<Route path={routes.login} element={<LoginPage />} />
			<Route path={routes.pricing} element={<PublicPlansPage />} />
			<Route path={routes.privacy} element={<PrivacyPage />} />
			<Route path={routes.promotions} element={<PromotionPage />} />
			<Route path={routes.kanban} element={<PublicRoadmapPage />} />
			<Route path={routes.signUp} element={<SignUpPage />} />
			<Route path={routes.terms} element={<TermsPage />} />
			<Route path={routes.workflows} element={<WorkflowsPage />} />
			<Route path="*" element={<Error404 />} />

			{/* Deprecated routes requiring redirects */}
			<Route path={routes.ourRoadmap} element={<Navigate to={routes.workflows} replace />} />

			{/* Redirect auth routes to login */}
			{authRoutes.map((route) => (
				<Route key={route} path={route} element={<Navigate to={routes.login} replace />} />
			))}
		</Routes>
	);
};
