import { useMemo } from "react";

import { useProjectsRepository } from "../hooks/useProjectsRepository";
import { IProjectsStore, useProjectsStore } from "../hooks/useProjectsStore";
import { IProjectsService, ProjectService } from "../services/nextProjectService";

export const useProjectsService = (): IProjectsService => {
	const projectStore = useProjectsStore();
	const projectRepository = useProjectsRepository();

	return useMemo(
		() => new ProjectService({ store: projectStore, repository: projectRepository }),
		[projectRepository, projectStore],
	);
};

export const projectGetHasMeta = (projectId: string) => {
	return (state: IProjectsStore) => {
		const project = state.projects.get(projectId);
		return !!(project?.type === "board" && project?.metaId);
	};
};
