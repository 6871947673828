import { Helmet } from "react-helmet-async";
import styled from "styled-components";

import { useErrorService } from "@/shared/core/hooks/useErrorService";

import { GlobalStyle } from "../../../App";
import { Button, Heading } from "../../../shared/system";
import { ThemeGlobalVariables } from "../../profile/theme/ThemeGlobalVariables";

interface Props {
	title: string;
	description: string;
	code: string;
	message?: string;
}

export function BaseError({ title, description, message, code }: Props) {
	const errorService = useErrorService();

	function navigateHome() {
		window.location.href = "/";
	}

	if (code === "500" && message) {
		errorService.logError(message);
	}

	return (
		<Root>
			<GlobalStyle />
			<ThemeGlobalVariables />
			<Helmet>
				<title>{title}</title>
				<meta content={description} name="description" />
			</Helmet>
			<Container>
				<Heading.H1 fontSize={128} mb={0} fontWeight={400}>
					{code}
				</Heading.H1>
				<Heading.H2 fontWeight={400}>{description}</Heading.H2>
				{message && <Message>{message}</Message>}
				<div>
					<Button rounded onClick={navigateHome}>
						Head back home
					</Button>
				</div>
			</Container>
		</Root>
	);
}

const Root = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	min-width: 50%;
	margin: auto;
`;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
	max-width: 860px;
	margin: 0 auto;
	padding: 0;
`;

const Message = styled.pre`
	padding: 24px;
	border-radius: 4px;
	background: var(--color-overlay);
`;
