import { Node, Rect } from "reactflow";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { nodeMaxCollisionDistance } from "@/domains/projects/components/tree/config/reactFlow";
import { isNumeric } from "@/shared/utils/isNumeric";

type Coordinates = { x: number; y: number };

type CollisionInformation = {
	id: NodeModel["id"];
	relation: "left" | "right" | "top" | "bottom" | "child";
	value: number;
};

export class CollisionUtils {
	private static centerOfRectangle(rect: Rect, left = rect.x, top = rect.y): Coordinates {
		return {
			x: left + rect.width * 0.5,
			y: top + rect.height * 0.5,
		};
	}

	private static sortCollisionsAsc({ value: a }: CollisionInformation, { value: b }: CollisionInformation) {
		return a - b;
	}

	private static distanceBetween(p1: Coordinates, p2: Coordinates) {
		return Math.sqrt(Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2));
	}

	private static getTopDropzone(rect: Rect): Rect {
		return {
			...rect,
			height: rect.height / 2,
		};
	}

	private static getBottomDropzone(rect: Rect): Rect {
		const halfHeight = rect.height / 2;

		return {
			...rect,
			y: rect.y - halfHeight,
			height: halfHeight,
		};
	}

	private static getLeftDropzone(rect: Rect): Rect {
		return {
			...rect,
			width: rect.width / 2,
		};
	}

	private static getRightDropzone(rect: Rect): Rect {
		const width = rect.width / 2;

		return {
			...rect,
			x: rect.x + width,
			width,
		};
	}

	private static getChildDropzone(rect: Rect): Rect {
		return {
			...rect,
			y: rect.y + rect.height + 48,
			height: 62,
		};
	}

	public static getClosestCenter = (node: Node, nodes: Node[]) => {
		const draggingRect = CollisionUtils.getNodeRect(node);
		const draggingCorners = CollisionUtils.centerOfRectangle(draggingRect);

		if (!draggingCorners) {
			return [];
		}

		const collisions: CollisionInformation[] = [];

		for (const droppableNode of nodes) {
			const droppableRect = CollisionUtils.getNodeRect(droppableNode);
			const localCollision: CollisionInformation[] = [];

			if (droppableNode.type === "listItem") {
				const topRect = CollisionUtils.getTopDropzone(droppableRect);
				const topRectCorners = CollisionUtils.centerOfRectangle(topRect);
				const topDistance = CollisionUtils.distanceBetween(draggingCorners, topRectCorners);

				localCollision.push({
					id: droppableNode.data.id,
					relation: "top",
					value: topDistance,
				});

				const bottomRect = CollisionUtils.getBottomDropzone(droppableRect);
				const bottomRectCorners = CollisionUtils.centerOfRectangle(bottomRect);
				const bottomDistance = CollisionUtils.distanceBetween(draggingCorners, bottomRectCorners);

				localCollision.push({
					id: droppableNode.data.id,
					relation: "bottom",
					value: bottomDistance,
				});
			}

			if (droppableNode.type === "card") {
				const leftRect = CollisionUtils.getLeftDropzone(droppableRect);
				const leftRectCorners = CollisionUtils.centerOfRectangle(leftRect);
				const leftDistance = CollisionUtils.distanceBetween(draggingCorners, leftRectCorners);

				localCollision.push({
					id: droppableNode.data.id,
					relation: "left",
					value: leftDistance,
				});

				const rightRect = CollisionUtils.getRightDropzone(droppableRect);
				const rightRectCorners = CollisionUtils.centerOfRectangle(rightRect);
				const rightDistance = CollisionUtils.distanceBetween(draggingCorners, rightRectCorners);

				localCollision.push({
					id: droppableNode.data.id,
					relation: "right",
					value: rightDistance,
				});

				if (droppableNode.data.children.length === 0) {
					const childRect = CollisionUtils.getChildDropzone(droppableRect);
					const childRectCorners = CollisionUtils.centerOfRectangle(childRect);
					const childDistance = CollisionUtils.distanceBetween(draggingCorners, childRectCorners);

					localCollision.push({
						id: droppableNode.data.id,
						relation: "child",
						value: childDistance,
					});
				}
			}

			const [collision] = localCollision.sort(CollisionUtils.sortCollisionsAsc);

			collision && collisions.push(collision);
		}

		return collisions.sort(CollisionUtils.sortCollisionsAsc).filter(({ value }) => value < nodeMaxCollisionDistance);
	};

	// Below is copied from reactflow as getNodeRect currently not exposed

	public static isRectObject = (obj: Record<any, any>): obj is Rect =>
		isNumeric(obj.width) && isNumeric(obj.height) && isNumeric(obj.x) && isNumeric(obj.y);

	public static nodeToRect = (node: Node): Rect => ({
		...(node.positionAbsolute || { x: 0, y: 0 }),
		width: node.width || 0,
		height: node.height ? node.height - 88 : 0, //Remove random padding put in to make layout good
	});

	public static getNodeRect(nodeOrRect: Node): Rect {
		const isRect = CollisionUtils.isRectObject(nodeOrRect);
		return isRect ? nodeOrRect : CollisionUtils.nodeToRect(nodeOrRect);
	}
}
