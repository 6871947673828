import { Auth, connectAuthEmulator, getAuth } from "firebase/auth";
import app from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { connectFirestoreEmulator, Firestore, getFirestore, Timestamp } from "firebase/firestore";
import { connectFunctionsEmulator, Functions, getFunctions } from "firebase/functions";

import config from "@/config/firebase";

export class Firebase {
	app: typeof app;
	auth: Auth;
	firestore: Firestore;
	rtdb: app.database.Database;
	functions: Functions;
	storage: app.storage.Reference;
	timestamp: () => Timestamp;

	constructor() {
		const firebaseApp = app.initializeApp(config);

		// Core services
		this.app = app;
		this.auth = getAuth(firebaseApp);
		this.firestore = getFirestore(firebaseApp);
		this.functions = getFunctions(firebaseApp);
		this.rtdb = app.database();
		this.storage = app.storage().ref();

		// Helpers
		this.timestamp = () => Timestamp?.fromDate(new Date());

		// Auth providers

		if (process.env.VITE_EMULATOR) {
			connectFirestoreEmulator(this.firestore, "localhost", 8080);
			connectAuthEmulator(this.auth, "http://localhost:9099", { disableWarnings: true });
			connectFunctionsEmulator(this.functions, "localhost", 5001);
			this.rtdb.useEmulator("localhost", 9000);
			this.app.storage().useEmulator("localhost", 9199);
		}
	}
}
