import { CreateProjectAction } from "@/domains/accounts/components/createProject/components/CreateProjectProject";
import { useListSelection } from "@/domains/accounts/components/createProject/hooks/useListSelection";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";

type Props = {
	folderId: string | undefined;
	actions: CreateProjectAction[];
};

export const projectTypesStateKey = "projectTypes";

export const CreateProjectProjectTypeShortcuts = ({ folderId, actions }: Props) => {
	// @ts-expect-error is possibly undefined
	const firstActionName = actions[0].name;

	const [activeAction] = useListSelection({
		id: projectTypesStateKey,
		items: actions,
		key: "name",
		defaultValue: firstActionName,
	});

	const getActiveAction = (project: CreateProjectAction) => project.name === activeAction;

	const handleAddActive = () => {
		if (!folderId) {
			return;
		}

		const activeAction = actions.find(getActiveAction);
		activeAction?.onClick();
	};

	useBindShortcut("return", handleAddActive);

	return null;
};
