import { differenceInMinutes, fromUnixTime } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { groupBy } from "lodash";

import { EventsDto } from "@/domains/nodes/dtos/eventsDto";
import { EventModel } from "@/domains/nodes/models/eventsModel";
import { EventsStore } from "@/domains/nodes/zustand/eventsStore";
import { fb } from "@/shared/infra/init";
import { guid } from "@/shared/utils/guid";

const EVENT_GROUP_THRESHOLD = 15; // minutes

export class EventUtils {
	public static checkWithinThreshold(event: EventModel) {
		const now = fromUnixTime(fb.timestamp().seconds);
		const lastActionTime = fromUnixTime(event.createdAt.seconds);
		const hoursSince = differenceInMinutes(now, lastActionTime);

		return hoursSince < EVENT_GROUP_THRESHOLD;
	}

	public static eventIsType(event: EventsDto, type: EventsDto["type"]) {
		return event.type === type;
	}

	public static groupEvents(events: EventsStore["events"]) {
		const groupedActivity = groupBy(Object.values(events), "groupId");
		const uniqueGroups = Object.keys(groupedActivity);

		return uniqueGroups.map((groupId) => {
			const activity = groupedActivity[groupId] as EventModel[];
			const createdAt = activity?.[0]?.createdAt as Timestamp;

			return {
				id: `g_${groupId}`,
				items: activity,
				type: "group" as const,
				createdAt,
			};
		});
	}

	public static getLastEventGroup(events: EventsStore["events"]) {
		const groupedEvents = EventUtils.groupEvents(events);
		return groupedEvents.pop();
	}

	public static getGroupId(events: EventsStore["events"]) {
		const lastGroup = EventUtils.getLastEventGroup(events);
		const lastEvent = lastGroup?.items.pop();

		if (!lastEvent) {
			return guid();
		}

		const isRecent = this.checkWithinThreshold(lastEvent);

		return isRecent ? lastEvent.groupId : guid();
	}

	public static getLastOfType(events: EventsStore["events"], next: EventModel) {
		const type = next.type;
		const lastGroup = EventUtils.getLastEventGroup(events);

		const groupedEvents = groupBy(lastGroup?.items, "type");
		const eventsForType = groupedEvents[type];

		if (!eventsForType) {
			return null;
		}

		if (type === "meta") {
			const metaEvents = eventsForType as (typeof next)[];

			return metaEvents.find((previous) => {
				return previous.value.id === next.value.id && previous.groupId === next.groupId;
			});
		}

		return eventsForType.slice(0).pop();
	}

	public static toViewModel(id: string, event: EventsDto): EventModel | undefined {
		const { action, groupId, createdBy, createdAt } = event;

		const base = {
			id,
			groupId,
			createdBy,
			createdAt,
		};

		if (event.type === "node-details") {
			return {
				...base,
				type: "details",
				value: null,
			};
		}

		if (event.type !== "nodes") {
			return;
		}

		switch (action) {
			case "create": {
				return {
					...base,
					type: "created",
					value: null,
				};
			}
			case "update": {
				if (!event.changed) {
					return;
				}
				const { title, meta } = event.changed;

				if (title) {
					return {
						...base,
						type: "title",
						value: { from: title, to: event.data.title },
					};
				} else if (meta) {
					const metaKey = Object.keys(meta)[0] as string;
					const metaData = event.data.meta?.[metaKey] as any;

					return {
						...base,
						type: "meta",
						value: { id: metaKey, ...metaData },
					};
				}

				return;
			}
			case "remove": {
				// No idea why there was no case for this picked up via eslint :S
				return;
			}
			default: {
				const _exhaustiveCheck: never = action;
				return _exhaustiveCheck;
			}
		}
	}
}
