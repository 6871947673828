import { z } from "zod";

import { themeModel, themeNames } from "@/modules/board/settings/components/theme/models/themeModel";
import { timestamp } from "@/shared/infra/firebase/firestore";

export const publicProfile = z.object({
	id: z.string(),
	username: z.string(), // or number 0 for system created
	photoURL: z.string().nullable(),
});

// --- End Common --- //

export const activityTypes = z.union([z.literal("nodeUpdateStatus"), z.literal("nodeCreated")]);

export const integrationSlack = z.object({
	version: z.literal("1"),
	webhook: z.object({
		url: z.string(),
		channel: z.string(),
		channelId: z.string(),
		configurationUrl: z.string(),
	}),
	on: activityTypes.array(),
});

export const projectStatus = z.union([z.literal("active"), z.literal("archived"), z.literal("deleted")]);

export const projectVisibility = z.union([z.literal("private"), z.literal("workspace")]);

const webhookCollections = z
	.union([z.literal("nodes"), z.literal("node-details"), z.literal("comments"), z.literal("key-results")])
	.array();

export const webhook = z.object({
	label: z.string().optional(),
	url: z.string().url(),
	on: webhookCollections.min(1, { message: "Select at least 1 event type" }),
	createdAt: timestamp,
	createdBy: publicProfile,
});

export const projectIntegrationWebhook = z.object({
	type: z.literal("webhook"),
	data: webhook,
});

export const projectIntegrationSlack = z.object({
	type: z.literal("slack"),
	data: z.object({
		on: webhookCollections,
		version: z.literal("1"),
		webhook: z.object({
			url: z.string(),
			channel: z.string().optional(),
			channelId: z.string().optional(),
			configurationUrl: z.string().optional(),
		}),
	}),
});

export const projectIntegration = z.discriminatedUnion("type", [projectIntegrationSlack, projectIntegrationWebhook]);

export type ProjectIntegration = z.infer<typeof projectIntegration>;
export type ProjectIntegrationSlack = z.infer<typeof projectIntegrationSlack>;
export type ProjectIntegrationWebhook = z.infer<typeof projectIntegrationWebhook>;
export type ProjectIntegrationWebhookData = ProjectIntegrationWebhook["data"];

export const seenBy = z.record(
	z.string(),
	z.object({
		active: z.boolean(),
		seenAt: timestamp,
		user: publicProfile,
	}),
);

export const projectData = z.object({
	name: z.string(),
	description: z.string().nullish(),
});

export const permissionsRole = z.enum(["admin", "editor", "commenter", "viewer", "suspended", "owner", "invite"]);
export const permissionsScope = z.enum(["workspace", "guest"]);
export const permissionsStatus = z.enum(["active", "suspended"]);

const projectPermissions = z.object({
	role: permissionsRole,
	scope: permissionsScope,
	status: permissionsStatus,
});

export const projectBase = z.object({
	status: projectStatus,
	visibility: projectVisibility,
	access: z.record(z.string(), projectPermissions).nullable(),
	workspaceId: z.string(),
	createdAt: timestamp,
	createdBy: publicProfile,
	updatedAt: timestamp,
	updatedBy: publicProfile,
	data: projectData,
	version: z.literal("1"),
});

const detailsSections = z.union([
	z.literal("cover"),
	z.literal("title"),
	z.literal("meta"),
	z.literal("children"),
	z.literal("activity"),
	z.literal("results"),
]);

const projectMetaTheme = z.object({
	name: themeNames,
	data: themeModel.optional(),
});

export type ProjectDtoMetaTheme = z.infer<typeof projectMetaTheme>;

const projectMetaRoadmap = z.object({
	groupId: z.string(),
	subGroupId: z.string().optional(),
	columnIds: z.string().array().nullable(),
	hiddenColumnIds: z.string().array().optional(),
	sortOrder: z.record(z.string(), z.string().array()),
});

export type ProjectDtoMetaRoadmap = z.infer<typeof projectMetaRoadmap>;

export const projectMeta = z.object({
	public: z.boolean(),
	hiddenNodeMetaIds: z.string().array(),
	hiddenDetailSectionKeys: detailsSections.array().optional(),
	groupedBy: z.string().optional(),
	view: z.union([z.literal("board"), z.literal("tree")]).optional(),
	collapsed: z.string().array().optional(),
	roadmap: projectMetaRoadmap.optional(),
	theme: projectMetaTheme.optional(),
});

export const projectDtoBoard = projectBase.extend({
	type: z.literal("board"),
	metaId: z.string().nullable(),
	rootId: z.string(),
	seenBy: seenBy,
	meta: projectMeta,
	integrations: projectIntegration.array().optional(),
});

export const projectDtoFolder = projectBase.extend({
	type: z.literal("folder"),
	childIds: z.string().array(),
});

export const projectDto = z.union([projectDtoBoard, projectDtoFolder]);

export type ProjectDto = z.infer<typeof projectDto>;
export type ProjectDtoBoard = z.infer<typeof projectDtoBoard>;
export type ProjectDtoFolder = z.infer<typeof projectDtoFolder>;
