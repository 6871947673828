import { formValues } from "@/domains/nodes/components/keyResults/components/forms/components/KeyResultForm";
import { IKeyResultsInput, KeyResultsFormFields } from "@/domains/nodes/components/keyResults/models/keyResultsModel";
import { Input, InputWrapper, Label, Validation } from "@/shared/system";

type Props = IKeyResultsInput<KeyResultsFormFields>;

export const InputTitle: React.FC<Props> = ({ register, errors }) => {
	const label = "Title";
	const name = "title" as const;

	const inputProps = register(name, { required: true });
	const error = errors[name];

	return (
		<div>
			<Label htmlFor={formValues.title}>{label}</Label>
			<InputWrapper>
				<Input type="text" id={name} placeholder="What does success look like?" isError={!!error} {...inputProps} mb="0" />
				<Validation label={label} error={error} />
			</InputWrapper>
		</div>
	);
};
