import { useState } from "react";

import { FiSliders } from "react-icons/fi";
import { VscTriangleDown } from "react-icons/vsc";
import styled from "styled-components";

import { MoreArchive } from "@/modules/board/settings/components/MoreArchive";
import { MoreCopyLink } from "@/modules/board/settings/components/MoreCopyLink";
import { MoreDepth } from "@/modules/board/settings/components/MoreDepth";
import { MoreDuplicate } from "@/modules/board/settings/components/MoreDuplicate";
import { MoreExport } from "@/modules/board/settings/components/MoreExport";
import { MoreExportModal } from "@/modules/board/settings/components/MoreExportModal";
import { MoreGrouping } from "@/modules/board/settings/components/MoreGrouping";
import { MoreImport } from "@/modules/board/settings/components/MoreImport";
import { MoreImportModal } from "@/modules/board/settings/components/MoreImportModal";
import { MoreMeta } from "@/modules/board/settings/components/MoreMeta";
import { MoreMetaTags } from "@/modules/board/settings/components/MoreMetaTags";
import { MoreSettings } from "@/modules/board/settings/components/MoreSettings";
import { MoreSlack } from "@/modules/board/settings/components/MoreSlack";
import { MoreUndo } from "@/modules/board/settings/components/MoreUndo";
import { DropdownHr } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { Popover, PopoverRender } from "@/shared/system/Dropdown/Popover";
import { ProjectBoard, WithId } from "@/types/db";

type Props = {
	board: WithId<ProjectBoard>;
};

export const ViewSettings = ({ board }: Props) => {
	const [isImportOpen, setImportOpen] = useState(false);
	const [isExportOpen, setExportOpen] = useState(false);

	const render: PopoverRender = ({ close }) => {
		const handleOpenImport = () => {
			setImportOpen(true);
			close();
		};

		const handleOpenExport = () => {
			setExportOpen(true);
			close();
		};

		return (
			<PopoverContent style={{ width: "220px" }}>
				<MoreDepth />
				<MoreGrouping board={board} />
				<DropdownHr />
				<MoreCopyLink board={board} onClick={close} />
				<MoreDuplicate board={board} onClick={close} />
				<DropdownHr />
				<MoreUndo />
				<DropdownHr />
				<MoreImport onClick={handleOpenImport} />
				<MoreExport onClick={handleOpenExport} />
				<DropdownHr />
				<MoreSlack project={board} />
				<DropdownHr />
				<MoreSettings board={board} />
				<DropdownHr />
				<MoreArchive project={board} onClick={close} />
				<DropdownHr />
				<MoreMetaTags docId={board.id} boardId={board.id} scope="project" />
				<DropdownHr />
				<MoreMeta board={board} />
			</PopoverContent>
		);
	};

	return (
		<>
			<Popover render={render} placement="bottom-end">
				<Trigger>
					<FiSliders className="settingsIcon" />
					<div>View</div>
					<VscTriangleDown />
				</Trigger>
			</Popover>
			<MoreExportModal board={board} isOpen={isExportOpen} setOpen={setExportOpen} />
			<MoreImportModal board={board} isOpen={isImportOpen} setOpen={setImportOpen} />
		</>
	);
};

const Trigger = styled.button`
	display: flex;
	align-items: center;
	color: var(--color-text-heading);
	font-size: 14px;
	line-height: 16px;
	padding: 10px 12px;
	border-radius: 4px;
	border: var(--border);
	background: var(--color-body);
	cursor: pointer;

	div {
		margin: 0 12px 0 6px;
	}

	> svg {
		font-size: 0.8em;
	}

	.settingsIcon {
		transform: rotate(90deg);
	}
`;
