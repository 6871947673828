import { RefObject } from "react";

import { useSetRecoilState } from "recoil";
import styled from "styled-components";

import { projectLocationStateKey } from "@/domains/accounts/components/createProject/components/CreateProjectLocation";
import { CreateProjectLocationFileFolder } from "@/domains/accounts/components/createProject/components/CreateProjectLocationFileFolder";
import { useListSelection } from "@/domains/accounts/components/createProject/hooks/useListSelection";
import { createProjectModalState } from "@/domains/accounts/components/createProject/selectors/createProjectModalState";
import { SidebarNavigationItemBoard } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationItemBoard";
import { NavigationTreeNode } from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { FileTree } from "@/shared/system/Files/FileTree";
import { dropdownState } from "@/shared/system/SelectDropdown/SelectDropdown";

type Props = {
	items: NavigationTreeNode[];
	inputEl: RefObject<HTMLInputElement>;
	close: (folderId?: string) => void;
};

const fileTypeMap = {
	board: SidebarNavigationItemBoard,
	folder: CreateProjectLocationFileFolder,
};

export const CreateProjectLocationFolders = ({ items, inputEl, close }: Props) => {
	const setActiveFolderId = useSetRecoilState(createProjectModalState);
	const setLocationSelectOpen = useSetRecoilState(dropdownState(projectLocationStateKey));

	const [activeFolder] = useListSelection({
		id: "locationFolder",
		items: items,
		key: "data",
		element: inputEl,
	});

	useBindShortcut(
		"return",
		() => {
			if (activeFolder) {
				setActiveFolderId(activeFolder.id);
				setLocationSelectOpen(false);
			}
		},
		inputEl,
	);

	return (
		<>
			{items.map((folder) => {
				const project = folder.data;

				if (project.type !== "folder") {
					return null;
				}

				const handleClick = () => {
					setActiveFolderId(project.id);
					setLocationSelectOpen(false);
					close();
				};

				const variant = activeFolder === project.id ? "active" : "";

				return (
					<div key={project.id}>
						<Header onClick={handleClick} className={variant}>
							{project.data.name}
						</Header>
						{project.childIds.map((projectId) => {
							return (
								<FileTree projectId={projectId} fileTypeMap={fileTypeMap} onClick={close} key={projectId} type="folder" />
							);
						})}
					</div>
				);
			})}
		</>
	);
};

const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: var(--color-text);
	padding: 4px 12px;
	border-radius: 4px;
	text-transform: uppercase;
	letter-spacing: 0.03em;
	font-size: 12px;

	:hover {
		background: var(--color-hover);

		.caret {
			visibility: visible !important;
		}
	}

	&.active {
		background: var(--color-hover);
	}
`;
