import { RefObject, useEffect } from "react";

import { useSetRecoilState } from "recoil";
import styled from "styled-components";

import { projectLocationStateKey } from "@/domains/accounts/components/createProject/components/CreateProjectLocation";
import { SearchResults } from "@/domains/accounts/components/createProject/components/SearchResults";
import { useListSelection } from "@/domains/accounts/components/createProject/hooks/useListSelection";
import { createProjectModalState } from "@/domains/accounts/components/createProject/selectors/createProjectModalState";
import { ProjectModel, ProjectModelFolder } from "@/domains/projects/models/projectsModel";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { dropdownState } from "@/shared/system/SelectDropdown/SelectDropdown";

type Props = {
	items: ProjectModel[];
	search: string;
	inputEl: RefObject<HTMLInputElement>;
	close: VoidFunction;
};

export const CreateProjectLocationSearchResults = ({ items, search, inputEl, close }: Props) => {
	const setActiveFolderId = useSetRecoilState(createProjectModalState);
	const setLocationSelectOpen = useSetRecoilState(dropdownState(projectLocationStateKey));

	const [activeFolderId, setSelectedId] = useListSelection({
		id: "locationFolderSearch",
		items: items,
		element: inputEl,
	});

	const nonRootItems = items.filter(
		(project): project is ProjectModelFolder => project.type === "folder" && project.createdBy.id !== "0",
	);

	const noResults = !nonRootItems[0];
	const firstResultId = !nonRootItems[0] ? null : nonRootItems[0].id;

	useEffect(() => {
		setSelectedId(firstResultId);
	}, [firstResultId, setSelectedId]);

	const handleClick = () => {
		if (activeFolderId) {
			setActiveFolderId(activeFolderId);
			setLocationSelectOpen(false);
		}
		close();
	};

	useBindShortcut("return", handleClick, inputEl);

	const handleHoverSearchResult = (id: string) => {
		setSelectedId(id);
	};

	return (
		<>
			{nonRootItems.map((item) => {
				return (
					<SearchResults
						item={item}
						activeId={activeFolderId}
						search={search}
						onMouseEnter={handleHoverSearchResult}
						onClick={handleClick}
						key={item.id}
					/>
				);
			})}
			{noResults && <NoResults>No results for "{search}"</NoResults>}
		</>
	);
};

const NoResults = styled.div`
	padding: 0 8px;
`;
