import { MenuNodeOptions } from "@/domains/nodes/components/menu/components/MenuNodeOptions";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

interface Props {
	rootEl: React.RefObject<HTMLDivElement>;
}

export const NodeContextMenu: React.FC<Props> = ({ rootEl }) => {
	const { canEdit } = usePermissions("project");

	const isEditing = useNodesStore((state) => state.local !== null);
	const node = useNodesStore((state) => (state.focusedId ? state.nodes[state.focusedId] : null));

	if (isEditing || !canEdit || !node) {
		return null;
	}

	return <MenuNodeOptions variant="context-menu" node={node} rootEl={rootEl} />;
};
