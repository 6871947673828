import { captureException } from "@sentry/browser";
import { arrayUnion, doc, runTransaction } from "firebase/firestore";

import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { defaultNote, NOTE_SCHEMA_VERSION } from "@/modules/note/noteFunctions";
import { db, fb } from "@/shared/infra/init";
import { ProjectBoard, ProjectData, ProjectMeta, ProjectVisibility, PublicProfile } from "@/types/db";

const defaultName = "Untitled";
const defaultMeta = {
	public: false,
	collapsed: [],
	hiddenNodeMetaIds: [],
	hiddenDetailSectionKeys: [],
	query: null,
	view: "tree" as const,
};

const projectDefaultsBoard = {
	type: "board" as const,
	status: "active" as const,

	guestIds: null,

	parentId: null,
	metaId: null,

	data: {
		name: defaultName,
		description: null,
	},
	seenBy: {},
	nodeCount: 0, //TODO: Deprecate ?
	integrations: [],

	version: "1" as const,
};

type ProjectCreateBoard = (args: {
	workspaceId: string;
	folderId: string;
	profile: PublicProfile;
	visibility?: ProjectVisibility;
	data?: ProjectData;
	meta?: Partial<ProjectMeta>;
}) => Promise<string>;

export const projectCreateBoard: ProjectCreateBoard = async ({
	workspaceId,
	folderId,
	visibility = "workspace",
	profile,
	data,
	meta,
}) => {
	const projectReference = doc(db.projects);
	const nodeReference = doc(db.nodes);
	const noteReference = doc(db.nodeNotes(nodeReference.id));
	const folderReference = doc(db.projects, folderId);
	const profileReference = doc(db.users, profile.id);

	const roleInfo = {
		status: "active" as const,
		role: "owner" as const,
		scope: "workspace" as const,
	};

	const project: ProjectBoard = {
		...projectDefaultsBoard,
		workspaceId,
		visibility,
		rootId: nodeReference.id,
		access: {
			[profile.id]: roleInfo,
		},
		createdAt: fb.timestamp(),
		createdBy: profile,
		updatedAt: fb.timestamp(),
		updatedBy: profile,
		meta: { ...defaultMeta, ...meta },
		...(data && { data }),
	};

	const rootNode = NodeUtils.create({
		workspace: workspaceId,
		board: projectReference.id,
		createdBy: profile.id,
		meta: {},
		overrides: {
			title: data?.name || defaultName,
		},
	});

	const note = {
		workspace: workspaceId,
		bodySchemaVersion: NOTE_SCHEMA_VERSION,
		body: defaultNote,
	};

	try {
		await runTransaction(fb.firestore, async (transaction) => {
			transaction.set(nodeReference, rootNode);
			transaction.set(projectReference, project);
			transaction.set(noteReference, note);

			transaction.set(
				folderReference,
				{
					childIds: arrayUnion(projectReference.id),
				},
				{ merge: true },
			);

			transaction.update(profileReference, {
				[`access.${projectReference.id}`]: roleInfo,
			});
		});
	} catch (e) {
		captureException(e);
	}

	return projectReference.id;
};
