import { AsyncCommand } from "@/shared/infra/services/BaseApi";
import { Result } from "@/shared/utils/result";

// TODO This type should be in the model, service shouldn't refernce components
import { RoadmapColumn } from "../components/roadmap/hooks/useRoadmapData";
import { ProjectDtoMetaRoadmap } from "../dtos/projectsDto";
import { ProjectsRepository } from "../hooks/useProjectsRepository";
import { IProjectsStore } from "../hooks/useProjectsStore";

export interface IProjectsService {
	roadmapSetColumnOrder: (projectId: string, columnIds: string[]) => AsyncCommand;
	roadmapSetSortOrder: (projectId: string, sortOrder: RoadmapColumn[]) => AsyncCommand;
	roadmapSetHiddenColumn: (projectId: string, columnId: string) => AsyncCommand;
}

type ConstructorArgs = {
	store: IProjectsStore;
	repository: ProjectsRepository;
};

export class ProjectService implements IProjectsService {
	private store;
	private repository;

	constructor({ store, repository }: ConstructorArgs) {
		this.store = store;
		this.repository = repository;
	}

	roadmapSetColumnOrder = async (projectId: string, columnIds: string[]): AsyncCommand => {
		this.store.setRoadmapColumnSortOrter(projectId, columnIds);
		return this.repository.updateProject(projectId, {
			"meta.roadmap.columnIds": columnIds,
		});
	};

	roadmapSetSortOrder = async (projectId: string, sortOrder: RoadmapColumn[]): AsyncCommand => {
		const nextSortOrder = sortOrder.reduce<ProjectDtoMetaRoadmap["sortOrder"]>((acc, column) => {
			acc[column.id] = column.nodes.map((node) => node.id);
			return acc;
		}, {});

		try {
			this.store.setRoadmapItemSortOrder(projectId, nextSortOrder);
		} catch (error) {
			return Result.fail(_errors.PS002);
		}

		return this.repository.updateProject(projectId, {
			"meta.roadmap.sortOrder": nextSortOrder,
		});
	};

	roadmapSetHiddenColumn = async (projectId: string, columnId: string): AsyncCommand => {
		const project = this.store.projects.get(projectId);

		if (!project) {
			return Result.fail(_errors.PS004);
		}

		if (project?.type !== "board") {
			return Result.fail(_errors.PS005);
		}

		const hiddenColumnIds = project.meta?.roadmap?.hiddenColumnIds?.slice(0) || [];

		if (hiddenColumnIds.includes(columnId)) {
			hiddenColumnIds.splice(hiddenColumnIds.indexOf(columnId), 1);
		} else {
			hiddenColumnIds.push(columnId);
		}

		this.store.setRoadmapHiddenColumns(projectId, hiddenColumnIds);

		return this.repository.updateProject(projectId, {
			"meta.roadmap.hiddenColumnIds": hiddenColumnIds,
		});
	};
}

const _errors = {
	PS001: "[PS001] Failed to update Roadmap meta",
	PS002: "[PS002] Failed to update Roadmap sort order",
	PS003: "[PS003] Failed to update Roadmap meta",
	PS004: "[PS004] Project not found",
	PS005: "[PS005] Atempting to set meta on invalid project type",
	PS006: "[PS006] Failed to update Roadmap meta",
};
