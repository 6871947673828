import styled from "styled-components";

type Props = {
	id?: string;
	values: any[] | string;
	empty?: JSX.Element;
	children?: React.ReactNode;
};

export const SelectedValues: React.FC<Props> = ({ values, children, empty }) => {
	const hasSelected = values.length > 0;

	if (hasSelected) {
		return <>{children}</>;
	}

	return (
		empty || (
			<Values>
				<Empty>Empty</Empty>
			</Values>
		)
	);
};

export const Values = styled.div`
	width: 100%;
	min-width: 0;
`;

const Empty = styled.span`
	display: inline-block;
	padding: 1px;
	color: var(--color-text);
`;
