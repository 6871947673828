import { useEffect } from "react";

import { isEmpty } from "lodash";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { getNodesWithDrafts } from "@/domains/nodes/zustand/nodesStore";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { HierarchyUtils } from "@/domains/projects/utils/hierarchyUtils";
import { useFilterService } from "@/modules/board/filter/hooks/useFilterService";

import { useTreeStore } from "./useTreeStore";

export const useBuildTree = () => {
	const nodes = useNodesStore.use.nodes();
	const local = useNodesStore.use.local();

	const collapsedNodeIds = useTreeStore.use.nodeCollapsedIds();
	const nodeHeights = useTreeStore.use.nodeHeights();

	const setTree = useTreeStore.use.setTree();
	const setFilteredTree = useTreeStore.use.setTreeFiltered();

	const project = useGetProject();
	const filterService = useFilterService();
	const rootId = filterService.getRootNodeId() || (project?.rootId as string);

	useEffect(() => {
		if (!rootId || isEmpty(nodes)) {
			return;
		}

		const nodesWithDrafts = getNodesWithDrafts(nodes, local);

		const treeOrError = HierarchyUtils.buildTree({ rootId, nodes: nodesWithDrafts, nodeHeights });

		if (treeOrError.isFailure) {
			return;
		}

		const tree = treeOrError.getValue();

		setTree(rootId, tree);

		const filteredTreeOrError = HierarchyUtils.buildFilteredTree({
			tree,
			nodes: nodesWithDrafts,
			rootId,
			collapsedNodeIds,
			nodeHeights,
		});

		if (filteredTreeOrError.isFailure) {
			return;
		}

		setFilteredTree(filteredTreeOrError.getValue());
	}, [rootId, collapsedNodeIds, local, nodes, nodeHeights, setFilteredTree, setTree]);
};
