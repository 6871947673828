import { FiEdit2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { getByLabel } from "@/domains/nodes/zustand/nodeMetaStore";
import { projectGetHasMeta } from "@/domains/projects/hooks/useProjectsService";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import routes from "@/shared/core/routes";
import { DropdownHeader, DropdownHr, TextWithEmoji } from "@/shared/system";
import { PopoverContent } from "@/shared/system/Dropdown/components/DropdownContent";
import { PopoverMenuLink } from "@/shared/system/Dropdown/components/DropdownMenuLink";
import { Popover, PopoverRender } from "@/shared/system/Dropdown/Popover";
import { NodeMetaAllTypes, NodeMetaSystemLabels, NodeMetaTypes, NodeMetaValueSelect } from "@/types/db";

const getItems = (meta?: NodeMetaAllTypes) => {
	switch (meta?.type) {
		case NodeMetaTypes.select: {
			return meta.value;
		}
		default:
			return [];
	}
};

type Props = {
	node: NodeModel;
	children?: React.ReactNode;
};

export const NodeActionsAddContainer: React.FC<Props> = ({ node, children }) => {
	const nodeMetaStore = useNodeMetaStore();
	const nodeMetaOrError = getByLabel(NodeMetaSystemLabels.nodeType, nodeMetaStore);

	const hasProjectMeta = useProjectsStore(projectGetHasMeta(node.board));

	const nodesService = useNodesService();

	const projectId = node.board;
	const parentId = node.id;

	if (nodeMetaOrError.isFailure) {
		return null;
	}

	const nodeMeta = nodeMetaOrError.getValue();
	const nodeMetaId = nodeMeta.id;
	const nodeMetaItems = getItems(nodeMeta);

	const editMetaRoute = hasProjectMeta ? `/s/${projectId}` : routes.account;

	const render: PopoverRender = ({ close }) => {
		const handleAddNode = (item: NodeMetaValueSelect) => {
			nodesService.localAdd({ parentId, data: { meta: { [nodeMetaId]: item.id } } });
			close();
		};

		return (
			<PopoverContent>
				<DropdownHeader>Select a {nodeMeta.label}...</DropdownHeader>
				{nodeMetaItems.map((item) => (
					<PopoverMenuLink key={item.id} onClick={() => handleAddNode(item)}>
						<TextWithEmoji text={item.title} />
					</PopoverMenuLink>
				))}
				<DropdownHr />
				<PopoverMenuLink as={Link} to={editMetaRoute}>
					<FiEdit2 /> <span>Edit {nodeMeta.label}</span>
				</PopoverMenuLink>
			</PopoverContent>
		);
	};

	return (
		<Popover render={render}>
			<Trigger>{children}</Trigger>
		</Popover>
	);
};

const Trigger = styled.div`
	display: flex;
	align-items: center;
	cursor: pointer;
`;
