import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";

type Props = {
	children?: React.ReactNode;
};

export const SortableListContext: React.FC<Props> = ({ children }) => {
	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 15,
			},
		}),
	);

	return (
		<DndContext sensors={sensors} collisionDetection={closestCenter}>
			{children}
		</DndContext>
	);
};
