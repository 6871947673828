import { useCallback } from "react";

import { useRecoilState } from "recoil";
import styled from "styled-components";

import { CreateProjectLocation } from "@/domains/accounts/components/createProject/components/CreateProjectLocation";
import { CreateProjectProjectTypes } from "@/domains/accounts/components/createProject/components/CreateProjectProjectTypes";
import { useCreateProjectActions } from "@/domains/accounts/components/createProject/hooks/useCreateProjectActions";
import { Modal } from "@/shared/system";
import { ModalHeader } from "@/shared/system/Modal/Modal";

import { createProjectModalState } from "./selectors/createProjectModalState";

export const CreateProject = () => {
	const [folderId, setOpen] = useRecoilState(createProjectModalState);

	const createProjectActions = useCreateProjectActions(folderId);

	const handleClose = useCallback(() => {
		setOpen(undefined);
	}, [setOpen]);

	const open = !!folderId;

	if (!open) {
		return null;
	}

	return (
		<Modal isOpen={open} padding="24px" onClose={handleClose} withClose={false}>
			<Location>
				<CreateProjectLocation folderId={folderId} />
			</Location>
			<ModalHeader>Create new</ModalHeader>
			<CreateProjectProjectTypes folderId={folderId} items={createProjectActions} />
		</Modal>
	);
};

const Location = styled.div`
	margin: -24px -24px 0;
	padding: 8px;
	border-bottom: var(--border);
	margin-bottom: 24px;
	cursor: pointer;

	:hover {
		background: var(--color-hover);

		kbd {
			background: var(--color-border);
		}
	}
`;
