import { useMemo } from "react";

import { Unsubscribe, UpdateData } from "firebase/firestore";
import produce from "immer";
import { omit, xor } from "lodash";
import { StoreApi } from "zustand";

import { INodeCreate } from "@/domains/nodes/components/cards/nodeCardTypes";
import { useEventsService } from "@/domains/nodes/hooks/useEventsService";
import { useNodesRepository } from "@/domains/nodes/hooks/useNodesRepository";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { addReminderToScheduledTasks, removeReminderFromScheduledTasks } from "@/domains/nodes/utils/scheduleUtils";
import { INodesStore, nodesStore } from "@/domains/nodes/zustand/nodesStore";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { TreeUtils } from "@/domains/projects/utils/treeUtils";
import { treeStore } from "@/domains/projects/zustand/treeStore";
import { useSubscriptionService } from "@/modules/plans/hooks/useSubscriptionService";
import { useProfileService } from "@/modules/profile/hooks/useProfileService";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";
import { Command, useActionHistory } from "@/shared/core/hooks/useActionHistory";
import { Result } from "@/shared/utils/result";

export type NodeAddPosition = "start" | "end" | "left" | "right";

type ArgsNodeAdd = {
	parentId: string;
	data: Partial<NodeModel>;
	position?: { activeId?: string; position?: NodeAddPosition };
	options?: { commit: boolean };
};

type ArgsNodeReorder = [NodeModel["id"], NodeModel, NodeModel, number];

export interface INodesService {
	archive(nodeId: NodeModel["id"]): Result<void>;
	collapseNode(nodeId: NodeModel["id"]): Result<void>;
	connect(projectId: NodeModel["board"]): Unsubscribe;
	localAdd(args: ArgsNodeAdd): Result<void>;
	localClear(): Result<void>;
	localCommit(title?: NodeModel["title"]): Result<void>;
	localRename(nodeId: NodeModel["id"]): Result<void>;
	localReorder(...args: ArgsNodeReorder): Result<void>;
	reorder(...args: ArgsNodeReorder): Result<void>;
	setVariant(nodeId: string, variant: NodeModel["variant"]): Result<void>;
	setHighlightPath(nodeId: string, highlight: boolean): Result<void>;
	update(nodeId: string, properties: UpdateData<NodeModel>): Result<void>;
	updateMeta(nodeId: string, metaId: string, value: any): Result<void>;
}

export const useNodesService = (): INodesService => {
	const profileService = useProfileService();
	const subscriptionService = useSubscriptionService();
	const historyService = useActionHistory();
	const analyticsService = useAnalyticsService();
	const treeService = useTreeService();

	const nodeStore = useNodesStore();

	const nodeRepository = useNodesRepository();

	const { addEvent } = useEventsService();

	return useMemo(() => {
		const archive: INodesService["archive"] = (nodeId) => {
			const nodeOrError = _getNode(nodeId, nodesStore);
			const childrenOrError = treeService.getChildren(nodeId);

			const result = Result.combine([nodeOrError, childrenOrError]);

			if (result.isFailure) {
				return Result.fail(result.getErrorValue());
			}

			const node = nodeOrError.getValue();
			const children = childrenOrError.getValue();

			const childIds = TreeUtils.toIds(children);

			const action = async () => {
				const result = await nodeRepository.archive(nodeId, childIds);

				if (result.isSuccess) {
					addEvent({
						type: "nodes",
						action: "remove",
						url: NodeUtils.buildNodeUrl(node),
						refId: nodeId,
						newData: node,
					});
				}
			};

			const undo = () => {
				nodeRepository.unarchive(nodeId, childIds);
			};

			historyService.execute(new Command({ name: "Delete Node", action, undo, value: undefined }));

			return Result.ok();
		};

		const collapseNode: INodesService["collapseNode"] = (nodeId) => {
			const nodeCollapsedIds = treeStore.getState().nodeCollapsedIds;

			const action = () => {
				const nextCollapsedNodes = xor(nodeCollapsedIds, [nodeId]);
				treeService.setNodeCollapsedIds(nextCollapsedNodes);
			};

			const undo = () => {
				treeService.setNodeCollapsedIds(nodeCollapsedIds);
			};

			historyService.execute(
				new Command({
					name: "Collapse Node",
					action,
					undo,
					value: undefined,
				}),
			);

			return Result.ok();
		};

		const connect: INodesService["connect"] = (projectId) => {
			const setData = nodesStore.getState().setData;
			return nodeRepository.listen(projectId, setData);
		};

		const _create = ({ node, parent }: INodeCreate): Result<void> => {
			const action = async () => {
				const result = await nodeRepository.createNode({ node, parent });

				if (result.isSuccess) {
					addEvent({
						type: "nodes",
						action: "create",
						url: NodeUtils.buildNodeUrl(node),
						refId: node.id,
						newData: node,
					});

					analyticsService.nodeCreated({ boardId: node.board, workspaceId: node.workspace });
				}
			};

			const undo = () => nodeRepository.archive(node.id);

			const redo = () => nodeRepository.unarchive(node.id);

			historyService.execute(new Command({ name: "Add Node", action, undo, redo, value: undefined }));

			return Result.ok();
		};

		const update: INodesService["update"] = (nodeId, properties) => {
			const nodeOrError = _getNode(nodeId, nodesStore);

			if (nodeOrError.isFailure) {
				return Result.fail("[NS001] Unable to find node to update");
			}

			const previous = nodeOrError.getValue();

			const next = {
				...omit(properties, ["id", "creating"]),
				...properties,
			};

			const action = async () => {
				const result = await nodeRepository.updateNode(nodeId, next);

				if (result.isSuccess) {
					addEvent({
						type: "nodes",
						action: "update",
						url: NodeUtils.buildNodeUrl(previous),
						refId: nodeId,
						newData: next,
						oldData: previous,
					});
				}

				analyticsService.nodeUpdated({
					boardId: previous.board,
					workspaceId: previous.workspace,
				});
			};

			const undo = () => {
				// TODO: Why does this use set rather than update ?
				nodeRepository.set(nodeId, previous);
			};

			historyService.execute(new Command({ name: "Updated Node", action, undo, value: undefined }));

			return Result.ok();
		};

		const updateMeta: INodesService["updateMeta"] = (nodeId, metaId, value) => {
			const nodeOrError = _getNode(nodeId, nodesStore);

			if (nodeOrError.isFailure) {
				return Result.fail("[NS002] Unable to find node to update");
			}

			const node = nodeOrError.getValue();

			const boardId = node.board;
			const workspaceId = node.workspace;

			const next = {
				...node,
				meta: {
					...node.meta,
					[metaId]: value,
				},
			};

			const previousMetaValue = node.meta?.[metaId] || null;
			const nextMetaValue = next.meta[metaId];

			const action = async () => {
				nodeStore.setNodeMeta(nodeId, metaId, nextMetaValue);

				const result = await nodeRepository.updateNode(nodeId, {
					[`meta.${metaId}`]: nextMetaValue,
				});

				if (result.isSuccess) {
					addEvent({
						type: "nodes",
						action: "update",
						url: NodeUtils.buildNodeUrl(node),
						refId: nodeId,
						newData: next,
						oldData: node,
					});

					next &&
						addReminderToScheduledTasks({
							nodeId,
							metaId,
							boardId,
							workspaceId,
							newValue: nextMetaValue,
						});
				}
			};

			const undo = () => {
				nodeStore.setNodeMeta(nodeId, metaId, previousMetaValue);
				nodeRepository.set(nodeId, node);

				removeReminderFromScheduledTasks({
					nodeId,
					metaId,
					boardId,
					workspaceId,
					newValue: nextMetaValue,
					oldValue: previousMetaValue,
				});
			};

			historyService.execute(new Command({ name: "Updated Meta", action, undo, value: undefined }));

			return Result.ok();
		};

		const localClear: INodesService["localClear"] = () => {
			nodesStore.getState().setLocal(null);

			return Result.ok();
		};

		const localCommit: INodesService["localCommit"] = (title) => {
			const local = nodesStore.getState().local;

			if (!local) {
				return Result.fail("[NS003] No local node to commit");
			}

			switch (local.type) {
				case "create": {
					const nextLocal = produce(local, (draft) => {
						if (title) {
							draft.data.node.title = title;
							draft.data.node.creating = false;
						}
					});

					nodeStore.setMergeLocal(nextLocal);

					return _create(nextLocal.data);
				}
				case "update": {
					const nodeId = local.data.node.id;
					const currentTitle = local.data.node.title;

					if (title === currentTitle) {
						localClear();
						return Result.ok();
					}

					const nextLocal = produce(local, (draft) => {
						if (title) {
							draft.data.node.title = title;
							draft.data.node.creating = false;
						}
					});

					nodeStore.setMergeLocal(nextLocal);

					return update(nodeId, { title });
				}
				case "reorder": {
					return Result.ok();
				}
				default: {
					const _exhaustiveCheck: never = local;
					return _exhaustiveCheck;
				}
			}
		};

		const localRename: INodesService["localRename"] = (nodeId) => {
			const nodeOrError = _getNode(nodeId, nodesStore);

			if (nodeOrError.isFailure) {
				return Result.fail("[NS004] Unable to find node to update");
			}
			const node = nodeOrError.getValue();

			nodesStore.setState({
				local: {
					type: "update",
					data: { node },
				},
			});

			return Result.ok();
		};

		const localAdd: INodesService["localAdd"] = ({ parentId, data, position = {}, options = { commit: false } }) => {
			const canAddOrError = subscriptionService.getCanAddNodes();
			const profileOrError = profileService.getPublicProfile();
			const parentOrError = _getNode(parentId, nodesStore);

			const result = Result.combine([canAddOrError, parentOrError, profileOrError]);

			if (result.isFailure) {
				return Result.fail(result.getErrorValue());
			}

			if (canAddOrError.getValue() === false) {
				return Result.fail("[NS005] Over workspace usage limit");
			}

			const parent = parentOrError.getValue();
			const profile = profileOrError.getValue();

			const boardId = parent.board;
			const workspaceId = parent.workspace;
			const profileId = profile.id;

			const node = NodeUtils.create({
				workspace: workspaceId,
				board: boardId,
				createdBy: profileId,
				meta: {},
				overrides: data,
			});

			nodesStore.getState().setFocused(node.id);

			const updatedParent = NodeUtils.addChild({
				node: parent,
				childId: node.id,
				...position,
			});

			nodesStore.setState({
				local: {
					type: "create",
					data: {
						node,
						parent: updatedParent,
					},
				},
			});

			if (options.commit) {
				return localCommit();
			}

			return Result.ok();
		};

		const localReorder: INodesService["localReorder"] = (nodeId, oldParent, newParent, position) => {
			const outgoingParentChildren = oldParent.children.filter((id) => id !== nodeId);
			const incomingParentChildren = newParent.children.filter((id) => id !== nodeId);

			incomingParentChildren.splice(position, 0, nodeId);

			nodesStore.getState().setLocal({
				type: "reorder",
				data: {
					outgoing: { ...oldParent, children: outgoingParentChildren },
					incoming: { ...newParent, children: incomingParentChildren },
				},
			});

			return Result.ok();
		};

		const reorder: INodesService["reorder"] = (nodeId, oldParent, newParent, position) => {
			const outgoingParentChildren = oldParent.children.filter((id) => id !== nodeId);
			const incomingParentChildren = newParent.children.filter((id) => id !== nodeId);

			incomingParentChildren.splice(position, 0, nodeId);

			const action = async () => {
				const incomingParent = {
					...newParent,
					children: incomingParentChildren,
				};

				const outgoingParent = {
					...oldParent,
					children: outgoingParentChildren,
				};

				await nodeRepository.newParent(incomingParent, outgoingParent);
				nodesStore.getState().setLocal(null);
			};

			const undo = async () => {
				await nodeRepository.newParent(oldParent, newParent);
			};

			historyService.execute(new Command({ name: "Reorder Node", action, undo, value: undefined }));

			return Result.ok();
		};

		const setVariant: INodesService["setVariant"] = (nodeId, variant) => {
			return update(nodeId, { variant });
		};

		const setHighlightPath: INodesService["setHighlightPath"] = (nodeId, highlighted) => {
			return update(nodeId, { highlighted });
		};

		return {
			archive,
			collapseNode,
			connect,
			localAdd,
			localClear,
			localCommit,
			localRename,
			localReorder,
			reorder,
			setVariant,
			setHighlightPath,
			update,
			updateMeta,
		};
	}, [
		addEvent,
		analyticsService,
		historyService,
		nodeRepository,
		nodeStore,
		profileService,
		subscriptionService,
		treeService,
	]);
};

const _getNode = (nodeId: string, store: StoreApi<INodesStore>): Result<NodeModel> => {
	const node = store.getState().nodes[nodeId];

	if (!node) {
		return Result.fail("[NS006] Unable to find node");
	}

	return Result.ok(node);
};
