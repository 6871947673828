import { useCallback } from "react";

import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useTreeStore } from "@/domains/projects/hooks/useTreeStore";
import { Command, useActionHistory } from "@/shared/core/hooks/useActionHistory";

type TreeDepthOptions = {
	depth: string;
	action: () => void;
};

const buildDepthOptions = (depth: number, action: (depth: string) => void): TreeDepthOptions[] => {
	const array = [...Array(depth + 1).keys()];
	array.shift();

	return array.map((depth) => {
		return {
			depth: depth.toString(),
			action: () => action(depth.toString()),
		};
	});
};

type UseSetTreeDepth = () => TreeDepthOptions[];

export const useTreeDepth: UseSetTreeDepth = () => {
	const treeService = useTreeService();

	const collapsedNodes = useTreeStore.use.nodeCollapsedIds();
	const setCollapsed = useTreeStore.use.setNodeCollapsedIds();

	const treeHeightOrError = treeService.getHeight();
	const depthMapOrError = treeService.getDepthMap();

	const { execute } = useActionHistory();

	const handleSetTreeDepth = useCallback(
		(newDepth: string): void => {
			if (depthMapOrError.isSuccess && newDepth !== "auto") {
				const depthMap = depthMapOrError.getValue();

				const newCollapsedNodes = Object.keys(depthMap).filter((nodeId) => {
					const nodeDepthInfo = depthMap[nodeId];
					if (nodeDepthInfo) {
						const matchesDepth = nodeDepthInfo.depth === parseInt(newDepth, 10);
						const hasChildren = nodeDepthInfo.hasChildren;

						return matchesDepth && hasChildren;
					}
					return false;
				});

				const action = () => setCollapsed(newCollapsedNodes);
				const undo = () => setCollapsed(collapsedNodes);

				execute(
					new Command({
						name: "Collapse to depth",
						action,
						undo,
						value: undefined,
					}),
				);
			}
		},
		[collapsedNodes, depthMapOrError, execute, setCollapsed],
	);

	const treeHeight = treeHeightOrError.isFailure ? 0 : treeHeightOrError.getValue();
	const actions = buildDepthOptions(treeHeight, handleSetTreeDepth);

	return actions;
};
