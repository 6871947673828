import { useEffect } from "react";

import { doc, onSnapshot } from "firebase/firestore";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { db, onSnapshotError } from "../../../shared/infra/init";
import { authenticatedIdState } from "../../authentication/selectors/authenticatedIdState";
import { profileState } from "../selectors/profileState";

export const useProfileListener = () => {
	const setProfileState = useSetRecoilState(profileState);
	const authenticatedId = useRecoilValue(authenticatedIdState);

	useEffect(() => {
		if (authenticatedId) {
			const userDocument = doc(db.users, authenticatedId);
			return onSnapshot(
				userDocument,
				(snapshot) => {
					const id = snapshot.id;
					setProfileState(Object.assign({}, { id }, snapshot.data()));
				},
				onSnapshotError("useProfileListener"),
			);
		}
	}, [authenticatedId, setProfileState]);
};
