import { useRecoilValue } from "recoil";

import { getPlanDetails } from "@/modules/plans/selectors/getPlanDetails";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

type Props = {
	children?: React.ReactNode;
};

export const NodeActionsPermissions = ({ children }: Props) => {
	const planDetails = useRecoilValue(getPlanDetails);
	const { canEdit } = usePermissions("project");

	const isOverNodeUsage = planDetails?.usage.nodes.isLocked;

	if (!canEdit || isOverNodeUsage) {
		return null;
	}

	return <>{children}</>;
};
