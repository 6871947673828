import { DocumentSnapshot } from "firebase/firestore";
import { z } from "zod";

import { INodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { guid } from "@/shared/utils/guid";
import { Result } from "@/shared/utils/result";
import { NodeMetaSelect, NodeMetaTypeUnion, NodeMetaValueSelect, ProjectBoard } from "@/types/db";

import { nodeMetaDateValue, nodeMetaDTO, nodeMetaSelectValue, nodeMetaTypes, nodeMetaTypeSelect } from "../dtos/nodeMetaDto";

const mapTuple = z.tuple([z.string(), nodeMetaTypes]);

export class NodeMetaUtil {
	/**
	 * @deprecated
	 *
	 * This should be removed once the "System" node meta types have been decommissioned
	 * rather than getting by label we should be utilising Map.get() and reference all meta by its ID
	 */
	public static getEntryByLabel(label: string) {
		return (meta: INodeMetaStore["meta"]) => {
			const iterable = [...meta.entries()];
			return iterable.find(([, _]) => _.label === label) || [];
		};
	}

	/**
	 * @deprecated
	 *
	 * This should be removed once the "System" node meta types have been decommissioned
	 * rather than getting by label we should be utilising Map.get() and reference all meta by its ID
	 */
	public static getByLabel(label: string) {
		return (meta: INodeMetaStore["meta"]) => {
			const iterable = [...meta.values()];
			return iterable.find((_) => _.label === label);
		};
	}

	public static getMetaValueById(meta: NodeMetaSelect, id: string): Result<NodeMetaValueSelect> {
		const value = meta.value.find((value) => id === value.id);

		if (!value) {
			return Result.fail("Unable to find meta value");
		}

		return Result.ok(value);
	}

	public static defaultValues: Record<NodeMetaTypeUnion, any> = {
		select: [],
		list: [],
		person: null,
		date: { format: "dd/LL/yyyy" },
		dateRange: { format: "dd/LL/yyyy" },
		link: null,
		text: null,
		email: null,
	};

	public static compatibleTypeValues: Record<NodeMetaTypeUnion, string[]> = {
		select: ["multi"],
		list: ["select"],
		person: [],
		date: ["dateRange"],
		dateRange: ["date"],
		link: [],
		email: [],
		text: ["link"],
	};

	public static createNewNodeMetaTemplate(): z.infer<typeof nodeMetaTypeSelect> {
		return {
			id: guid(),
			type: "select",
			label: "Property",
			value: [],
		};
	}

	public static buildNewValue<TMetaType extends z.infer<typeof nodeMetaTypes>>(
		meta: TMetaType,
		value: any,
	): TMetaType["value"] {
		switch (meta.type) {
			case "select":
			case "list": {
				return [
					nodeMetaSelectValue.parse({
						id: guid(),
						color: null,
						...value,
					}),
					...meta.value,
				];
			}
			case "date":
			case "dateRange": {
				return nodeMetaDateValue.parse({
					format: value,
				});
			}
			default: {
				return null;
			}
		}
	}

	public static getHiddenMetaIds(node: NodeModel, project?: ProjectBoard) {
		const hiddenMetaNode = node.metaHidden || [];
		const hiddenMetaProject = project?.meta.hiddenNodeMetaIds || [];

		// Creates and array of unique values
		return Array.from(new Set([...hiddenMetaProject, ...hiddenMetaNode]));
	}

	public static mapNodeValues(node: NodeModel, meta: NodeMetaModel): { value: any; definition: any }[] {
		if (meta.size === 0) {
			return [];
		}

		return [...meta.values()]
			.map(({ id }) => {
				return {
					value: node.meta?.[id] || "",
					definition: meta.get(id) as z.infer<typeof nodeMetaTypes>,
				};
			})
			.filter(({ value, definition }) => value !== undefined && definition !== undefined)
			.filter(({ value }) => (Array.isArray(value) ? value.length > 0 : true));
	}

	public static toViewModel(snapshot: DocumentSnapshot<z.infer<typeof nodeMetaDTO>>) {
		const dto = snapshot.data();

		if (!dto) {
			return {
				id: "",
				meta: new Map(),
			};
		}

		const { order, meta } = nodeMetaDTO.parse(dto);

		const arrayToMap = (id: string) => mapTuple.parse([id, meta[id]]);

		return {
			id: snapshot.id,
			meta: new Map(order.map(arrayToMap)),
		};
	}
}
