import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router-dom";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NoteContainer } from "@/modules/note/components/NoteContainer";
import { useGetNote } from "@/modules/note/hooks/useGetNote";
import { getParentPath } from "@/modules/note/utils/nodeNoteUtils";
import { SidebarRight } from "@/shared/system";

type NodeDetailsParams = { boardId: string; nodeId: string };

export const NodeDetails: React.FC = () => {
	const { nodeId } = useParams() as NodeDetailsParams;
	const navigate = useNavigate();

	useGetNote();

	const node = useNodesStore((state) => state.nodes[nodeId]);

	if (!node) {
		return null;
	}

	const nodeTitle = node.title;

	const boardId = node.board;

	const handleClose = () => {
		navigate(`${getParentPath()}/${boardId}`);
	};

	return (
		<SidebarRight onClose={handleClose} width={660}>
			<Helmet>
				<title>{nodeTitle}</title>
				<meta property="og:title" content={nodeTitle} />
				<meta property="og:description" content={""} />
			</Helmet>
			<NoteContainer node={node} />
		</SidebarRight>
	);
};

export default NodeDetails;
