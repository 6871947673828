import { useCallback } from "react";

import * as Accordion from "@radix-ui/react-accordion";
import { Dictionary } from "lodash";
import styled from "styled-components";

import { SidebarNavigationAddProject } from "@/domains/accounts/components/sidebarNavigation/components/SidebarNavigationAddProject";
import { NavigationTreeNode } from "@/domains/accounts/components/sidebarNavigation/utils/navigationUtils";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { projectUpdateAll } from "@/modules/board/api/projectUpdateAll";

import { Props as SidebarNavigationContainer, SidebarNavigationFolderContainer } from "./SidebarNavigationFolderContainer";

const getChildIds = (flattenedTree: NavigationTreeNode[], parentId: string) => {
	return flattenedTree.filter((items) => items.parent?.data.id === parentId).map((node) => node.data.id);
};

interface Props {
	tree: NavigationTreeNode;
	canAdd?: boolean;
	hideIfEmpty?: boolean;
}

export const SidebarNavigationFolder = ({ tree, canAdd = false, hideIfEmpty = false }: Props) => {
	const rootProject = tree?.data;

	const { setProjects } = useProjectsStore();

	const handleReorder: SidebarNavigationContainer["onDrop"] = useCallback(
		({ previous, next, active, all }) => {
			if (next.id === previous.id) {
				const projectsUpdated: Dictionary<ProjectModel> = {
					[next.id]: {
						...next,
						childIds: getChildIds(all, next.id),
					},
				};

				setProjects(projectsUpdated);
				projectUpdateAll(projectsUpdated);
			} else {
				const projectsUpdated: Dictionary<ProjectModel> = {
					[previous.id]: {
						...previous,
						childIds: previous.childIds.filter((id: any) => id !== active.data.id),
					},
					[next.id]: {
						...next,
						childIds: getChildIds(all, next.id),
					},
				};
				setProjects(projectsUpdated);
				projectUpdateAll(projectsUpdated);
			}
		},
		[setProjects],
	);

	if (!rootProject || rootProject.type !== "folder") {
		return null;
	}

	const isEmpty = tree.height === 0;

	if (hideIfEmpty && isEmpty) {
		return null;
	}

	return (
		<Accordion.Root type="single" defaultValue="item-1" collapsible>
			<Accordion.Item value="item-1">
				<TriggerGroup>
					<Trigger>{rootProject.data.name}</Trigger>
					{canAdd && <SidebarNavigationAddProject projectId={rootProject.id} />}
				</TriggerGroup>
				<Content>
					<SidebarNavigationFolderContainer tree={tree} onDrop={handleReorder} />
					{isEmpty && <Empty>No projects found...</Empty>}
				</Content>
			</Accordion.Item>
		</Accordion.Root>
	);
};

const TriggerGroup = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 6px;
`;

const Empty = styled.div`
	padding: 6px;
	font-size: 14px;
	font-style: italic;
`;
const Trigger = styled(Accordion.Trigger)`
	background: transparent;
	border: 0;
	outline: 0;
	padding: 0;
	font-size: 14px;
`;

const Content = styled(Accordion.Content)`
	padding: 6px 0;
	overflow: hidden;

	&[data-state="open"] {
		animation: slideDown 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}
	&[data-state="closed"] {
		animation: slideUp 300ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	@keyframes slideDown {
		from {
			height: 0;
		}
		to {
			height: var(--radix-accordion-content-height);
		}
	}

	@keyframes slideUp {
		from {
			height: var(--radix-accordion-content-height);
		}
		to {
			height: 0;
		}
	}
`;
