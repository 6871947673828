import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { handleBottomStyle } from "@/domains/projects/components/tree/config/reactFlow";
import { BoardHeader } from "@/modules/board/components/BoardHeader";
import { Node, WithId } from "@/types/db";

export const TreeNodeSource: React.FC<NodeProps<WithId<Node>>> = ({ id }) => {
	const node = useNodesStore((state) => state.nodes[id]);

	if (!node) {
		return null;
	}

	return (
		<Root>
			<BoardHeader node={node} variant="tree" />
			<Handle id={`source-${id}`} type="source" position={Position.Bottom} style={handleBottomStyle} />
		</Root>
	);
};

const Root = styled.div`
	> * {
		border: 0;
	}
`;
