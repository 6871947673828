import { useEffect } from "react";

import { useProjectsRepository } from "@/domains/projects/hooks/useProjectsRepository";
import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";

export const useGetProjectListener = (projectId: string) => {
	const projectsRepository = useProjectsRepository();

	const setProjectSingle = useProjectsStore((state) => state.setProjectSingle);
	const setIsLoadingSingle = useProjectsStore((state) => state.setIsLoadingSingle);

	useEffect(() => {
		if (projectId) {
			setIsLoadingSingle(true);

			const onError = () => {
				setProjectSingle({
					code: 401,
					data: null,
				});
			};

			return projectsRepository.listen(projectId, setProjectSingle, onError);
		}
	}, [projectId, projectsRepository, setIsLoadingSingle, setProjectSingle]);
};
