import { z } from "zod";

import { nodeMetaTypeDate, nodeMetaTypeDateRange } from "@/domains/nodes/dtos/nodeMetaDto";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

import { Node, NodeMetaDataDateTypes, WithId } from "../../../../../../../types/db";
import { DatePickerSelectedValue } from "./DatePickerSelectedValue";
import { NodeMetaDateValue } from "./NodeMetaDateValue";

interface Props {
	meta: z.infer<typeof nodeMetaTypeDate> | z.infer<typeof nodeMetaTypeDateRange>;
	node: WithId<Node>;
}

export const NodeMetaDate = ({ node, meta }: Props) => {
	const { canEdit } = usePermissions("project");

	const value = node?.meta?.[meta.id] as NodeMetaDataDateTypes;
	const format = meta.value.format;

	const editableValue = canEdit ? (
		<NodeMetaDateValue nodeId={node.id} metaId={meta.id} value={value} label={meta.label} format={format} />
	) : (
		<DatePickerSelectedValue value={value} dateFormat={format} />
	);

	return editableValue;
};
