import * as Accordion from "@radix-ui/react-accordion";
import styled from "styled-components";

export const AccordionTrigger = styled(Accordion.Trigger)`
	display: flex;
	gap: 6px;
	align-items: center;
	background: transparent;
	border: 0;
	padding: 0;
	cursor: pointer;
	color: var(--color-text-secondary);
	margin: 0;

	.caret {
		font-size: 12px;
		transform-origin: center;
		transform: rotate(-90deg);
		transition: transform 150ms cubic-bezier(0.87, 0, 0.13, 1);
	}

	&[data-state="open"] > .caret {
		transform: rotate(0deg);
	}

	&:focus {
		outline: 0;
	}
`;

export const AccordionContent = styled(Accordion.Content)`
	overflow: hidden;

	&[data-state="open"] {
		animation: slideDown 150ms ease-in-out;
	}

	&[data-state="closed"] {
		animation: slideUp 150ms ease-in-out;
	}

	@keyframes slideDown {
		from {
			height: 0;
		}
		to {
			height: var(--radix-accordion-content-height);
		}
	}

	@keyframes slideUp {
		from {
			height: var(--radix-accordion-content-height);
		}
		to {
			height: 0;
		}
	}
`;
