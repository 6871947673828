import { NodeStore } from "@/domains/nodes/models/nodesModel";
import { TreeModel } from "@/domains/projects/models/treeModel";

export class TreeUtils {
	public static toIds(tree: TreeModel[]): string[] {
		return tree.map((node) => node.data.id);
	}

	public static toNodeModel(nodes: TreeModel[]): NodeStore {
		return nodes.reduce<NodeStore>((carry, node) => {
			carry[node.data.id] = node.data.document;
			return carry;
		}, {});
	}
}
