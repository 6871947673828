import { memo } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { useNodeMetaListener } from "@/domains/nodes/components/details/meta/hooks/useNodeMetaListener";
import { useKeyResultsListener } from "@/domains/nodes/components/keyResults/hooks/useKeyResultsListener";
import { useNodesListener } from "@/domains/nodes/hooks/useNodesListener";
import { useBuildTree } from "@/domains/projects/hooks/useBuildTree";
import { useGetProjectListener } from "@/domains/projects/hooks/useGetProjectListener";
import { useBoardMembersWorkspace } from "@/modules/board/hooks/useBoardMembersWorkspace";
import { BaseApi } from "@/shared/infra/services/BaseApi";

import { useGuestInvitesListener } from "../hooks/useGuestInvitesListener";
import { useGuestListener } from "../hooks/useGuestListener";
import { useGetBoardMeta } from "../meta/hooks/useBoardMeta";

export const ProjectListeners = memo(() => {
	const { boardId } = useParams() as { boardId: string };
	const navigate = useNavigate();

	useGetProjectListener(boardId);

	// Dependant on project
	useNodeMetaListener();
	useBuildTree();
	useBoardMembersWorkspace();

	useKeyResultsListener();
	useGetBoardMeta(boardId);
	useGuestListener(boardId);
	useNodesListener(boardId);
	useGuestInvitesListener(boardId);

	const sanitizedId = BaseApi.sanitizeId(boardId);

	if (sanitizedId !== boardId) {
		navigate(`/b/${sanitizedId}`);
	}

	return null;
});

ProjectListeners.displayName = "ProjectListeners";
