/**
 * Initial dimension of nodes when added to the tree, these should
 * be recalculated when the node is saved, to ensure there is no overlap
 * with tree view nodes
 */
import { TreeNodeCard } from "@/domains/projects/components/tree/components/TreeNodeCard";
import { TreeNodeListAdd } from "@/domains/projects/components/tree/components/TreeNodeListAdd";
import { TreeListItem } from "@/domains/projects/components/tree/components/TreeNodeListItem";
import { TreeNodeSource } from "@/domains/projects/components/tree/components/TreeNodeSource";
import { TreeNodeTypes } from "@/domains/projects/models/treeModel";

export const proOptions = { account: "", hideAttribution: true };

export const nodeTypes = {
	source: TreeNodeSource,
	card: TreeNodeCard,
	list: TreeNodeListAdd,
	listItem: TreeListItem,
};

export const nodeTempId = "temp-node";
export const nodeMaxCollisionDistance = 120;

export const handleStyle = { border: 0, background: "transparent" };
export const handleTopStyle = { ...handleStyle, top: 0 };
export const handleBottomStyle = { ...handleStyle, bottom: 0 };

export const getDefaultNodeDimensions = (type: TreeNodeTypes, height?: number): [number, number] => {
	const defaults = {
		source: { width: 600, height: 284 },
		card: { width: 262, height: 98 },
		list: { width: 500, height: 158 },
		listItem: { width: 500, height: 58 },
	};

	const typeDefault = defaults[type];

	const calcHeight = height ? height + 64 : typeDefault.height;

	return [typeDefault.width, calcHeight];
};
