import React from "react";

import styled from "styled-components";

type Variants = "active" | "inactive";

export interface MenuItemProps {
	onClick: () => void;
	icon?: React.ReactElement;
	appearance?: Variants;
	className?: string;
	children?: React.ReactNode;
}

export const MenuItem: React.FC<MenuItemProps> = ({ onClick, className, children, appearance = "inactive" }) => {
	return (
		<Root onClick={onClick} appearance={appearance} className={className}>
			{children}
		</Root>
	);
};

const Root = styled.button<{ appearance: Variants }>`
	display: flex;
	align-items: center;
	background: transparent;
	border: 0;
	font-size: 18px;
	border-radius: 4px;
	line-height: 0.9;
	padding: 6px;
	cursor: pointer;

	:hover {
		color: var(--color-accent-hover);
		background: var(--color-hover);
	}

	${({ appearance }) => {
		switch (appearance) {
			case "active":
				return `
					color: var(--color-accent-hover);
					background: var(--color-hover);
				`;
			case "inactive":
				return `color: var(--color-text);`;
			default:
				return "";
		}
	}}
`;
