import { doc, writeBatch } from "firebase/firestore";
import { useRecoilValue } from "recoil";

import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";

import { db, fb } from "../../../shared/infra/init";
import { Node } from "../../../types/db";
import { WithId } from "../../../types/db/utils";
import { getPlanDetails } from "../../plans/selectors/getPlanDetails";
import { usePublicProfile } from "../../profile/hooks/usePublicProfile";
import { buildPrepareNode, prepareNote } from "./useCloneBoard";

export const useCloneSubTree = () => {
	const nodes = useNodesStore((state) => state.nodes);
	const profile = usePublicProfile();
	const planDetails = useRecoilValue(getPlanDetails);
	const analyticsService = useAnalyticsService();

	const overNodeQuota = planDetails?.usage.nodes.isLocked;

	if (overNodeQuota || !profile) {
		return () => {};
	}

	return async function (root: WithId<Node>, parent: WithId<Node> | undefined) {
		const lookup: Record<string, string> = {};
		const postOrderIds: string[] = [];

		const prepareNode = buildPrepareNode(root.board, profile.id);

		const workspaceId = root.workspace;

		const nodeBatch = writeBatch(fb.firestore);
		const noteBatch = writeBatch(fb.firestore);

		const traverse = (node: WithId<Node>) => {
			if (!node) {
				return;
			}

			for (const child of node.children) {
				// @ts-expect-error is possibly undefined
				traverse(nodes[child]);
			}
			// @ts-expect-error is possibly undefined
			const nodeData = prepareNode(nodes[node.id], lookup);
			const newNodeDoc = doc(db.nodes);
			nodeBatch.set(newNodeDoc, nodeData);

			const newNodeId = newNodeDoc.id;

			analyticsService.nodeCreated({ boardId: root.board, workspaceId: root.workspace });

			lookup[node.id] = newNodeId;
			postOrderIds.push(newNodeId);
		};

		traverse(root);

		const newChildren = parent?.children.slice(0) || [];
		const rootIndex = newChildren.findIndex((n) => n === root.id);
		const rootId = postOrderIds.pop();

		if (rootId && parent) {
			const docReference = doc(db.nodes, parent.id);
			newChildren.splice(rootIndex, 0, rootId);

			nodeBatch.update(docReference, {
				children: newChildren,
			});
		}

		await nodeBatch.commit();

		for (const nodeId in lookup) {
			const noteData = await prepareNote(nodeId, workspaceId);
			// @ts-expect-error is possibly undefined
			const noteDoc = doc(db.nodeNotes(lookup[nodeId]));
			noteBatch.set(noteDoc, noteData);
		}

		await noteBatch.commit();
	};
};
