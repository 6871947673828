import { useEffect } from "react";

import { onSnapshot, query, where } from "firebase/firestore";
import { useRecoilValue } from "recoil";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { activeWorkspaceIdState } from "@/modules/authentication/hooks/useAuthenticationListener";
import { Dictionary, ProjectStatus, ProjectVisibility } from "@/types/db";

import { db, onSnapshotError } from "../../../shared/infra/init";

interface UseProjectListenerArgs {
	status?: ProjectStatus;
	visibility?: ProjectVisibility;
	userId?: string | null;
}
export const useProjectsListener = ({ visibility = "workspace", userId = null }: UseProjectListenerArgs = {}) => {
	const workspaceId = useRecoilValue(activeWorkspaceIdState);

	const setProjects = useProjectsStore((state) => state.setProjects);
	const setIsLoading = useProjectsStore((state) => state.setIsLoading);

	useEffect(() => {
		if (workspaceId) {
			setIsLoading(true);

			let projectQuery = query(
				db.projects,
				where("workspaceId", "==", workspaceId),
				where("status", "==", "active"),
				where("visibility", "==", visibility),
			);

			if (userId) {
				projectQuery = query(projectQuery, where(`access.${userId}.status`, "==", "active"));
			}

			return onSnapshot(
				projectQuery,
				(snapshot) => {
					const data = snapshot.docs.reduce<Dictionary<ProjectModel>>((carry, doc) => {
						return {
							...carry,
							[doc.id]: {
								...doc.data(),
								group: visibility,
								id: doc.id,
							},
						};
					}, {});

					setProjects(data);
				},
				onSnapshotError("useProjectsListener"),
			);
		}
	}, [setIsLoading, setProjects, userId, visibility, workspaceId]);
};
