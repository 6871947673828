import { createGlobalStyle, css } from "styled-components";

import { UserTheme } from "@/types/db";

import { useProfileLegacy } from "../hooks/useProfileLegacy";

export const ThemeGlobalVariables = () => {
	const profile = useProfileLegacy();

	switch (profile?.theme) {
		case UserTheme.light: {
			return (
				<>
					<BaseTheme />
					<LightThemeFixed />
				</>
			);
		}
		case UserTheme.dark: {
			return (
				<>
					<BaseTheme />
					<DarkThemeFixed />
				</>
			);
		}
		default:
			return (
				<>
					<BaseTheme />
					<LightThemeMediaQuery />
					<DarkThemeMediaQuery />
					{!profile && <LightThemeFixed />}
				</>
			);
	}
};

const DarkTheme = css`
	:root {
		--color-accent: #b48df3;
		--color-body: color(display-p3 0.02 0.05 0.11 / 1);
		--color-contrast: #ffffff;
	}
`;

const LightTheme = css`
	:root {
		--color-accent: #8154f2;
		--color-body: #fdfdfd;
		--color-contrast: #000000;
	}
`;

const BaseTheme = createGlobalStyle`
	:root {
		--color-accent-hover: color-mix(in srgb, var(--color-accent), var(--color-contrast) 12%);
		--color-text: color-mix(in srgb, var(--color-text-heading), var(--color-body) 30%);
		--color-text-heading: color-mix(in srgb, var(--color-contrast)  75%, var(--color-body)); 
		--color-text-secondary: var(--color-text);
		--color-border: color-mix(in srgb, var(--color-text), var(--color-body) 75%);
		--color-border-dark: color-mix(in srgb, var(--color-text), var(--color-body) 95%);
		--color-hover: color-mix(in srgb, var(--color-text), var(--color-body) 90%);
		--color-overlay: color-mix(in srgb, var(--color-contrast), transparent 95%);

		--gradient-overlay: linear-gradient(180deg, #00000005, #00000066);
		--gradient-striped: repeating-linear-gradient(
			45deg,
			var(--color-border),
			var(--color-border) 1px,
			transparent 0,
			transparent 12px
		);
		--gradient-striped-accent: repeating-linear-gradient(
			45deg,
			var(--color-accent),
			var(--color-accent) 1px,
			transparent 0,
			transparent 12px
		);

		--calendar-icon: invert(1);

		--border: 1px solid var(--color-border);

		--color-drop-shadow: rgba(0, 0, 0, 0.1);

		--drop-shadow: 0px 2px 4px -2px rgba(0, 0, 0, 0.08), 0px 2px 4px -2px rgba(0, 0, 0, 0.04);
		--drop-shadow-hover: 0px 6px 14px var(--color-drop-shadow);

		--drop-shadow-l2: 0px 8px 16px var(--color-drop-shadow);
		--drop-shadow-l2-hover: 0px 10px 22px var(--color-drop-shadow);
	}
`;

const DarkThemeMediaQuery = createGlobalStyle`
    @media (prefers-color-scheme: dark) {
		${DarkTheme}
	}
`;

const DarkThemeFixed = createGlobalStyle`
	${DarkTheme}

	
`;

const LightThemeMediaQuery = createGlobalStyle`
    @media (prefers-color-scheme: light) {
		${LightTheme}
	}
`;

export const LightThemeFixed = createGlobalStyle`
	${LightTheme}
`;
