import produce, { enableMapSet } from "immer";

import { ProjectDtoMetaRoadmap } from "@/domains/projects/dtos/projectsDto";
import { ProjectModel, ProjectModelBoard, ProjectSingle } from "@/domains/projects/models/projectsModel";
import { Result } from "@/shared/utils/result";
import { createStore } from "@/shared/zustand/createStore";
import { Dictionary } from "@/types/db";

enableMapSet();

type State = {
	isLoading: boolean;
	isLoadingSingle: boolean;
	projects: Map<ProjectModel["id"], ProjectModel>;
	projectSingle: ProjectSingle | null;
};

type Actions = {
	removeProject: (projectId: ProjectModel["id"]) => void;
	setProjectMeta: (projectId: string, data: any) => void;
	setIsLoading: (isLoading: boolean) => void;
	setProjects: (projects: Dictionary<ProjectModel>) => void;
	setProjectSingle: (project: ProjectSingle) => void;
	setRoadmapItemSortOrder: (projectId: string, sortOrder: ProjectDtoMetaRoadmap["sortOrder"]) => void;
	setIsLoadingSingle: (isLoading: boolean) => void;
	setRoadmapColumnSortOrter: (projectId: string, columnIds: string[]) => void;
	setRoadmapHiddenColumns: (projectId: string, columnIds: string[]) => void;
};

export type IProjectsStore = State & Actions;

export const projectsStore = createStore<IProjectsStore>((set, get) => ({
	isLoading: true,
	isLoadingSingle: true,
	projects: new Map(),
	projectSingle: null,
	removeProject: (projectId) => {
		set((state) => ({
			projects: produce(state.projects, (draft) => {
				draft.delete(projectId);
			}),
		}));
	},
	setProjectMeta: (projectId, data) => {
		const projects = get().projects;
		const project = get().projects.get(projectId);

		if (!project) {
			return;
		}

		if (project.type === "folder") {
			return;
		}

		// TODO: This should use immer here
		const newProjects = projects.set(projectId, {
			...project,
			meta: {
				...project.meta,
				roadmap: {
					...data,
				},
			},
		});

		set({
			projects: newProjects,
		});
	},
	setRoadmapItemSortOrder: (projectId, nextSortOrder) => {
		set((state) => ({
			projects: produce(state.projects, (draft) => {
				const draftProject = draft.get(projectId);

				if (draftProject?.type !== "board") {
					throw new Error("Atempting to set meta on invalid project type");
				}

				if (draftProject.meta.roadmap) {
					draftProject.meta.roadmap.sortOrder = nextSortOrder;
				} else {
					draftProject.meta.roadmap = {
						groupId: "",
						subGroupId: "",
						hiddenColumnIds: [],
						sortOrder: nextSortOrder,
						columnIds: [],
					};
				}
			}),
		}));
	},
	setRoadmapColumnSortOrter: (projectId, columnIds) => {
		set((state) => ({
			projects: produce(state.projects, (draft) => {
				const project = draft.get(projectId);

				if (project?.type !== "board") {
					throw new Error("Atempting to set meta on invalid project type");
				}

				if (project.meta.roadmap) {
					project.meta.roadmap.columnIds = columnIds;
				} else {
					project.meta.roadmap = {
						groupId: "",
						subGroupId: "",
						hiddenColumnIds: [],
						sortOrder: {},
						columnIds,
					};
				}
			}),
		}));
	},
	setRoadmapHiddenColumns: (projectId, hiddenColumnIds) => {
		set((state) => ({
			projects: produce(state.projects, (draft) => {
				const project = draft.get(projectId);

				if (project?.type !== "board") {
					throw new Error("Atempting to set meta on invalid project type");
				}

				if (project.meta.roadmap) {
					project.meta.roadmap.hiddenColumnIds = hiddenColumnIds;
				} else {
					project.meta.roadmap = {
						groupId: "",
						subGroupId: "",
						hiddenColumnIds,
						sortOrder: {},
						columnIds: [],
					};
				}
			}),
		}));
	},
	setIsLoading: (isLoading) => set({ isLoading }),
	setIsLoadingSingle: (isLoadingSingle) => set({ isLoadingSingle }),
	setProjects: (newProjects) => {
		const projects = get().projects;
		const newProjectMap = new Map(Object.entries(newProjects));

		set({ isLoading: false, projects: new Map([...projects, ...newProjectMap]) });
	},

	setProjectSingle: (project) => {
		const projects = get().projects;

		set({ projectSingle: project });

		if (project?.code === 200) {
			const nextProjects = produce(projects, (draft) => {
				draft.set(project.data.id, {
					...project.data,
					group: "single" as const,
				});
			});

			set({ isLoadingSingle: false, projects: nextProjects });
		}
	},
}));

export const getBoard = (projectId: string) => {
	return (state: IProjectsStore): Result<ProjectModelBoard> => {
		const project = state.projects.get(projectId);

		if (!project) {
			return Result.fail("Unable to find project for Id");
		}

		if (project.type !== "board") {
			return Result.fail("Supplied Id is not a board");
		}

		return Result.ok(project);
	};
};
