import { useMemo } from "react";

import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { ProjectDtoBoard } from "@/domains/projects/dtos/projectsDto";
import { WithId } from "@/types/db";

import { MemberInvited } from "../../../../members/components/MemberInvited";
import { guestInviteState, useGuestInvitesListener } from "../../../hooks/useGuestInvitesListener";
import { boardGuestState, useGuestListener } from "../../../hooks/useGuestListener";
import { ShareMembersMember, UserWithRole } from "./ShareMembersMember";

type Props = {
	project: WithId<ProjectDtoBoard>;
};

export const ShareMembers = ({ project }: Props) => {
	const projectId = project.id;

	useGuestListener(projectId);
	useGuestInvitesListener(projectId);

	const members = useRecoilValue(boardGuestState(projectId));
	const invited = useRecoilValue(guestInviteState(projectId));

	const membersWithRole = useMemo(() => {
		return Object.values(members)
			.reduce<UserWithRole[]>((carry, member) => {
				const access = member.access?.[projectId];
				const next = carry.slice(0);

				if (access?.status !== "active") {
					return carry;
				}

				next.push({
					...member,
					// @ts-expect-error is possibly undefined
					role: member.access?.[projectId]?.role,
				});

				return next;
			}, [])
			.reverse();
	}, [projectId, members]);

	return (
		<Root>
			{membersWithRole.map((member) => (
				<ShareMembersMember member={member} project={project} ownerId={project.createdBy.id} key={member.id} />
			))}
			{invited.map((invite) => (
				<MemberInvited invite={invite} key={invite.id} variant="nested" />
			))}
		</Root>
	);
};

const Root = styled.div`
	margin-top: 8px;
	margin-bottom: 8px;
`;
