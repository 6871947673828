import { useState } from "react";

import * as RadixMenu from "@radix-ui/react-dropdown-menu";
import { FiTrash } from "react-icons/fi";
import styled from "styled-components";
import { z } from "zod";

import { nodeMetaTypes } from "@/domains/nodes/dtos/nodeMetaDto";
import { ContextItem } from "@/modules/nodeContextMenu/components/ContextItem";
import { getNodeMetaTypeIcon } from "@/modules/nodeMeta/utils/getNodeMetaTypeIcon";
import { getNodeMetaTypes } from "@/modules/nodeMeta/utils/getNodeMetaTypes";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import {
	Dropdown,
	DropdownContent,
	DropdownHeader,
	DropdownHr,
	DropdownPortal,
	DropdownSubContent,
	DropdownTrigger,
} from "@/shared/system";
import { NodeMetaTypeUnion } from "@/types/db";

import { DetailsMetaItemVisibility } from "./components/DetailsMetaItemVisibility";
import { useNodeMetaActions } from "./hooks/useNodeMetaActions";
import { NodeMetaSelectOptionsItemEditLabelInput } from "./types/select/NodeMetaSelectOptionsItemEditLabelInput";

interface Props {
	meta: z.infer<typeof nodeMetaTypes>;
}

export const NodeMetaLabel = ({ meta }: Props) => {
	const { id: metaId, type, label } = meta;
	const { canEdit } = usePermissions("project");
	const { deleteNodeMeta, updateNodeMetaType, updateNodeMetaLabel } = useNodeMetaActions();

	const [open, setOpen] = useState<boolean>(false);

	const item = {
		id: metaId,
		label: getNodeMetaTypes.find((meta) => meta.type === type)?.label as string,
		icon: getNodeMetaTypeIcon(type),
		disabled: false,
		children: getNodeMetaTypes,
	};

	const deleteProperty = {
		id: "delete",
		label: "Delete Property",
		icon: FiTrash,
	};

	// START BUSINESS LOGIC

	const handleUpdateLabel = ({ isDirty, label }: any) => {
		if (isDirty) {
			updateNodeMetaLabel(metaId, label);
		}
	};

	const handleDeleteProperty = () => {
		deleteNodeMeta(metaId);
	};

	const handleUpdateType = (newType: NodeMetaTypeUnion) => {
		updateNodeMetaType(metaId, newType, type);
	};

	const handleClose = () => setOpen(false);

	//TEMP Until dependency has been removed
	const isSystemType = meta.label === "Node Type" || meta.label === "Status";

	const Icon = getNodeMetaTypeIcon(type);

	return (
		<Dropdown open={open} onOpenChange={setOpen}>
			<DropdownTrigger disabled={!canEdit} data-testid="node-meta-label__trigger">
				<Root canEdit={canEdit}>
					<Icon /> <span>{label}</span>
				</Root>
			</DropdownTrigger>

			<DropdownPortal>
				<DropdownContent align="start">
					{!isSystemType && (
						<>
							<NodeMetaSelectOptionsItemEditLabelInput value={label} onUpdate={handleUpdateLabel} close={handleClose} />
							<DropdownHeader>Property type</DropdownHeader>
						</>
					)}

					{!isSystemType && (
						<>
							<RadixMenu.Sub>
								<RadixMenu.SubTrigger>
									<Item item={item} key={item.id} hasChildren />
								</RadixMenu.SubTrigger>
								<RadixMenu.Portal>
									<DropdownSubContent sideOffset={2} alignOffset={-5}>
										{item.children.map((child) => (
											<RadixMenu.Item
												onSelect={() => handleUpdateType(child.type)}
												key={child.label}
												data-testid={`node-meta-label__meta-type--${child.label.toLowerCase()}`}>
												<Item item={child} key={child.label} isSelected={child.type === type} />
											</RadixMenu.Item>
										))}
									</DropdownSubContent>
								</RadixMenu.Portal>
							</RadixMenu.Sub>

							<DropdownHr />
						</>
					)}
					<DetailsMetaItemVisibility metaId={metaId} />

					{!isSystemType && (
						<RadixMenu.Item onSelect={handleDeleteProperty}>
							<Item item={deleteProperty} />
						</RadixMenu.Item>
					)}
				</DropdownContent>
			</DropdownPortal>
		</Dropdown>
	);
};

const Root = styled.div<{ canEdit: boolean }>`
	display: flex;
	align-items: center;
	margin-right: 12px;
	border-radius: 4px;
	padding: 6px 10px;
	width: 180px;
	color: var(--color-text);
	font-weight: 500;

	span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		flex-grow: 1;
	}

	svg {
		font-size: 18px;
		margin-right: 10px;
		flex-shrink: 0;
	}

	${({ canEdit }) => {
		return (
			canEdit &&
			`
				cursor: pointer;

				&:hover {
					background: var(--color-hover);
					}
			`
		);
	}}
`;

const Item = styled(ContextItem)`
	padding-left: 12px;
`;
