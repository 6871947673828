import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import ReactFlow, {
	Node,
	NodeChange,
	NodeDragHandler,
	NodeMouseHandler,
	useEdgesState,
	useNodesState,
	useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import styled from "styled-components";

import { useNodeFocus } from "@/domains/nodes/components/cards/hooks/useNodeFocus";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeUtils } from "@/domains/nodes/utils/nodeUtils";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";
import { Result } from "@/shared/utils/result";

import { Controls } from "./components/Controls/Controls";
import { TreeLoading } from "./components/TreeLoading";
import { nodeTempId, nodeTypes, proOptions } from "./config/reactFlow";
import { useNodesAndEdges } from "./hooks/useNodesAndEdges";
import { CollisionUtils } from "./utils/collisionUtils";

export const Tree = () => {
	const navigate = useNavigate();
	const { boardId } = useParams();

	const treeService = useTreeService();
	const nodesService = useNodesService();

	const [isLayedOut, setLayout] = useState(false);
	const timeoutFocusIntentRef = useRef<number>();

	const { canEdit } = usePermissions("project");
	const isEditing = useNodesStore((state) => state.local !== null);

	const { focusedId, setFocused } = useNodeFocus();

	const { nodes: flowNodes, edges: flowEdges } = useNodesAndEdges();

	const [edges, setEdges, onEdgesChange] = useEdgesState(flowEdges);
	const [nodes, setNodes, onNodesChange] = useNodesState(flowNodes);

	const { setViewport, getNodes } = useReactFlow();

	const isInteractive = canEdit && !isEditing;

	useEffect(() => {
		setTimeout(() => {
			setLayout(true);
		}, 500);

		return () => {
			boardId && setLayout(false);
		};
	}, [boardId]);

	useEffect(() => {
		setNodes(flowNodes);
		setEdges(flowEdges);
	}, [flowEdges, flowNodes, setEdges, setNodes]);

	useLayoutEffect(() => {
		const pane = document.querySelector(".react-flow__pane");

		if (!isLayedOut && pane) {
			const rect = pane.getBoundingClientRect();
			const halfCardWidth = 130;
			const midPoint = rect.width / 2 - halfCardWidth;

			setViewport({ x: midPoint, y: 0, zoom: 1 }, { duration: 0 });
		}
	}, [isLayedOut, setViewport]);

	const handleNodeChange = useCallback(
		(changes: NodeChange[]) => {
			onNodesChange(changes);

			for (const change of changes) {
				if (change.type === "dimensions" && change.id !== nodeTempId) {
					const height = change.dimensions?.height;

					if (height) {
						treeService.setNodeHeight(change.id, height);
					}
				}
			}
		},
		[onNodesChange, treeService],
	);

	const handleDragStart: NodeDragHandler = useCallback(
		(_, node) => {
			setNodes((nodes) => {
				return [
					{
						...node,
						id: nodeTempId,
						className: "opaque",
					},
					...nodes,
				];
			});

			setEdges((edges) => {
				return edges.map((edge) => {
					if (edge.target === node.id) {
						return {
							...edge,
							target: nodeTempId,
						};
					}
					if (edge.source === node.id) {
						return {
							...edge,
							source: nodeTempId,
						};
					}
					return edge;
				});
			});
		},
		[setNodes, setEdges],
	);

	const _resetDraggingState = useCallback(() => {
		setNodes(flowNodes);
		setEdges(flowEdges);
	}, [setNodes, flowNodes, setEdges, flowEdges]);

	const _getOverNode = useCallback(
		(draggingNode: Node) => {
			const descendantIds = treeService.getDescendants(draggingNode.id).map((tree) => tree.data.id);

			// These filters are important to avoid invalid collisions. handleDragEnd and handeDragNode niaevely assume that
			// when a collision is returned it is valid.
			const nodes = getNodes()
				.filter(({ id }) => id !== nodeTempId)
				.filter(({ id }) => id !== draggingNode.id)
				.filter(({ type }) => type !== "source")
				.filter(({ id }) => !descendantIds.includes(id));

			// TODO: This should probably also do getOver then GetClosestCenter (this is what the kanban board does)
			const [over] = CollisionUtils.getClosestCenter(draggingNode, nodes);

			return over;
		},
		[treeService, getNodes],
	);

	// 1. Clear any nodes modified during drag (collision pointers)
	// 2. Calculate new state and update
	const handleDragEnd: NodeDragHandler = useCallback(
		(_, draggingNode) => {
			const over = _getOverNode(draggingNode);

			const draggingId = draggingNode.id;
			const overId = over?.id;

			if (!overId) {
				_resetDraggingState();
				return;
			}

			const nextParentOrError = over.relation === "child" ? treeService.getNode(overId) : treeService.getParent(overId);
			const currentParentOrError = treeService.getParent(draggingId);

			const result = Result.combine([nextParentOrError, currentParentOrError]);

			if (result.isFailure) {
				_resetDraggingState();
				return;
			}

			const nextParent = nextParentOrError.getValue().data.document;
			const currentParent = currentParentOrError.getValue().data.document;

			const overIndex = nextParent.children.findIndex((id) => id === overId);
			const overPosition = over.relation === "left" ? Math.max(overIndex, 0) : Math.max(overIndex + 1, 0);
			const nextPosition = over.relation === "child" ? 0 : overPosition;

			const currentPosition = currentParent.children.findIndex((id) => id === draggingId);

			const noChange = nextParent.id === currentParent.id && nextPosition === currentPosition;

			if (noChange) {
				_resetDraggingState();
				return;
			}

			nodesService.reorder(draggingId, currentParent, nextParent, nextPosition);
		},
		[treeService, nodesService, _getOverNode, _resetDraggingState],
	);

	const handleDragNode: NodeDragHandler = useCallback(
		(_, draggingNode) => {
			setFocused(null);

			const descendantIds = treeService.getDescendants(draggingNode.id).map((tree) => tree.data.id);
			const over = _getOverNode(draggingNode);

			setNodes((nodes) =>
				nodes.map((node) => {
					if (node.id === nodeTempId) {
						return node;
					}

					if (descendantIds.includes(node.id)) {
						return {
							...node,
							className: "opaque",
						};
					}

					return {
						...node,
						className: over?.id === node.id ? over.relation : "",
					};
				}),
			);
		},
		[setFocused, treeService, _getOverNode, setNodes],
	);

	const handleNodeClick: NodeMouseHandler = useCallback(
		(event, node) => {
			if (node.data.creating) {
				return;
			}

			const target = event.target as HTMLElement;
			const isNodeCard = target.closest(`[data-node-id]`);

			if (isNodeCard) {
				navigate(NodeUtils.buildNodeUrl(node.data));
			}
		},
		[navigate],
	);

	const handleNodeMouseEnter: NodeMouseHandler = useCallback(
		(_, node) => {
			if (!node.dragging) {
				timeoutFocusIntentRef.current = window.setTimeout(() => {
					setFocused(node.id);
				}, 250);
			}
		},
		[setFocused],
	);

	const handleNodeMouseLeave: NodeMouseHandler = useCallback(() => {
		if (timeoutFocusIntentRef.current) {
			clearTimeout(timeoutFocusIntentRef.current);
		}
	}, []);

	const handleClearFocus = useCallback(() => {
		focusedId && setFocused(null);
	}, [focusedId, setFocused]);

	return (
		<Root id="treeRoot">
			{!isLayedOut && <TreeLoading />}
			<ReactFlow
				edges={edges}
				elementsSelectable={false}
				minZoom={0.25}
				nodes={nodes}
				nodesConnectable={false}
				nodesDraggable={isInteractive}
				nodeTypes={nodeTypes}
				onEdgesChange={onEdgesChange}
				onNodeClick={handleNodeClick}
				onNodeDrag={handleDragNode}
				onNodeDragStart={handleDragStart}
				onNodeDragStop={handleDragEnd}
				onNodeMouseEnter={handleNodeMouseEnter}
				onNodeMouseLeave={handleNodeMouseLeave}
				onNodesChange={handleNodeChange}
				onPaneClick={handleClearFocus}
				onMove={handleClearFocus}
				panOnDrag={!isEditing}
				panOnScroll={true}
				proOptions={proOptions}
				zoomOnDoubleClick={false}
			/>
			<Controls />
		</Root>
	);
};

const Root = styled.div`
	width: calc(100% - 16px);
	height: 100%;
	margin: 0 8px;

	.react-flow__node {
		border: 1px solid transparent;
		&.dragging {
			z-index: 4 !important;
			opacity: 0.9;
		}

		&.highlighted :not([data-layout="icon"]) {
			.draggable {
				border: 1px solid var(--color-accent);
			}
		}

		&.opaque {
			border: var(--border);
			border-radius: 8px;
			background: var(--gradient-striped);

			> * {
				visibility: hidden;
			}
		}

		&.top,
		&.bottom,
		&.left,
		&.right {
			&::before,
			&::after {
				position: absolute;
				display: block;
				content: " ";
			}

			&::before {
				background: var(--color-accent);
			}

			&::after {
				height: 6px;
				width: 6px;
				border-radius: 6px;
				border: 2px solid var(--color-accent);
				background: var(--color-body);
			}
		}

		&.top,
		&.bottom {
			&::before,
			&::after {
				left: -16px;
			}

			&::before {
				width: calc(100% + 24px);
				height: 2px;
			}
		}

		&.top {
			&::before {
				top: -9px;
			}

			&::after {
				top: -13px;
			}
		}

		&.bottom {
			&::before {
				bottom: -8px;
			}

			&::after {
				bottom: -12px;
			}
		}

		&.left,
		&.right {
			&::before,
			&::after {
				top: -6px;
			}

			&::before {
				height: calc(100% + 12px);
				width: 2px;
			}
		}

		&.left {
			&::before {
				left: -14px;
			}

			&::after {
				left: -18px;
			}
		}

		&.right {
			&::before {
				right: -12px;
			}

			&::after {
				right: -16px;
			}
		}

		&.child {
			&::before {
				position: absolute;
				z-index: 2;
				bottom: -24px;
				left: calc(50% - 1px);
				display: block;
				content: " ";
				height: 24px;
				border-left: 2px solid var(--color-body);
			}

			&::after {
				position: absolute;
				bottom: -68px;
				display: block;
				content: " ";
				border: 1px solid var(--color-accent);
				background: var(--gradient-striped-accent);
				width: calc(100% - 6px);
				height: 48px;
				border-radius: 8px;
			}
		}
	}
`;
