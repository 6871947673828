import { useMemo } from "react";

import { DocumentSnapshot, Unsubscribe, UpdateData } from "firebase/firestore";

import { ProjectModel } from "@/domains/projects/models/projectsModel";
import { ProjectsUtil } from "@/domains/projects/utils/projectUtils";
import { IProjectsStore } from "@/domains/projects/zustand/projectsStore";
import { IErrorService, useErrorService } from "@/shared/core/hooks/useErrorService";
import { fb } from "@/shared/infra/init";
import { AsyncCommand, BaseApi } from "@/shared/infra/services/BaseApi";
import { Result } from "@/shared/utils/result";
import { ProjectDto } from "@/types/db";

type ConstructorArgs = {
	errorService: IErrorService;
};

export class ProjectsRepository extends BaseApi<ProjectDto> {
	private errorService;

	constructor({ errorService }: ConstructorArgs) {
		super("projects");

		this.errorService = errorService;

		this.listen.bind(this);
		this.updateProject.bind(this);
	}

	public listen(projectId: string, save: IProjectsStore["setProjectSingle"], error?: () => void): Unsubscribe {
		const setState = (snapshot: DocumentSnapshot<ProjectDto>) => {
			const viewModel = ProjectsUtil.toViewModel(snapshot);
			save(viewModel);
		};

		return this.documentSnapshot(projectId, setState, error);
	}

	public async updateProject(projectId: string, data: UpdateData<ProjectModel>): AsyncCommand {
		try {
			await this.patch(projectId, { ...data, updatedAt: fb.timestamp() });
			return Result.ok();
		} catch (error) {
			this.errorService.logError(_projectErrors.PR001, error);
		}

		return Result.fail("Failed to update project");
	}
}

const _projectErrors = {
	PR001: "[PR001] Failed to update project",
};

export const useProjectsRepository = () => {
	const errorService = useErrorService();

	return useMemo(() => new ProjectsRepository({ errorService }), [errorService]);
};
