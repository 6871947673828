import { useCallback } from "react";

import { FiArchive, FiEdit2 } from "react-icons/fi";
import { useRecoilState, useSetRecoilState } from "recoil";

import { useProjectsStore } from "@/domains/projects/hooks/useProjectsStore";
import { boardDeleteState } from "@/modules/board/BoardConfirmDelete";
import { sidebarNavigationState } from "@/modules/page/selectors/sidebarNavigationState";
import { Menu } from "@/shared/system/Menu/Menu";
import { MenuItem } from "@/shared/system/Menu/MenuItem";

interface Props {
	rootEl: React.RefObject<HTMLDivElement>;
}

export const SidebarNavigationContextMenu: React.FC<Props> = ({ rootEl }) => {
	const [[projectId], setProjectId] = useRecoilState(sidebarNavigationState);

	const project = useProjectsStore((state) => (projectId ? state.projects.get(projectId) : null));

	const handleDelete = useSetRecoilState(boardDeleteState);

	const handleOpen = useCallback(
		(event: MouseEvent) => {
			const contextEl = document.elementFromPoint(event.pageX, event.pageY);
			const projectId = contextEl?.closest("[data-project-id]")?.getAttribute("data-project-id");

			if (projectId) {
				event.preventDefault();
				setProjectId([projectId, undefined]);
				return true;
			}

			return false;
		},
		[setProjectId],
	);

	if (!project) {
		return <Menu variant="context-menu" onContextOpen={handleOpen} triggerEl={rootEl} />;
	}

	const projectType = project.type;

	switch (projectType) {
		case "board": {
			return (
				<Menu variant="context-menu" onContextOpen={handleOpen} triggerEl={rootEl}>
					<MenuItem icon={FiArchive} label="Delete board" handleClick={() => handleDelete(projectId)} />
				</Menu>
			);
		}
		case "folder": {
			return (
				<Menu variant="context-menu" onContextOpen={handleOpen} triggerEl={rootEl}>
					<MenuItem icon={FiEdit2} label="Rename" handleClick={() => setProjectId([projectId, "rename"])} />
					<MenuItem icon={FiArchive} label="Delete folder" handleClick={() => handleDelete(projectId)} />
				</Menu>
			);
		}
		default: {
			const _exhaustiveCheck: never = projectType;
			return _exhaustiveCheck;
		}
	}
};
