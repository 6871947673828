import { mergeAttributes, Node, nodeInputRule } from "@tiptap/core";

import { UploadFn, uploadImagePlugin } from "../plugins/uploadImagePlugin";

// TODO: Markdown is buggy but should match the following following attributes in Markdown-typed image: [, alt, src, title]

/**
 * https://gist.github.com/slava-vishnyakov/16076dff1a77ddaca93c4bccd4ec4521
 *
 * Example:
 * ![Lorem](image.jpg) -> [, "Lorem", "image.jpg"]
 * ![](image.jpg https://www.py4u.net/static/python-LOGO.svg) -> [, "", "image.jpg", "Ipsum"]
 * ![Lorem](image.jpg "Ipsum") -> [, "Lorem", "image.jpg", "Ipsum"]
 */

interface ImageOptions {
	inline: boolean;
	HTMLAttributes: Record<string, any>;
}

const IMAGE_INPUT_REGEX = /!\[(.+|:?)\]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;

export const createImageExtension = (uploadFn: UploadFn) => {
	return Node.create<ImageOptions>({
		name: "image",
		draggable: true,
		inline() {
			return this.options.inline;
		},

		group() {
			return this.options.inline ? "inline" : "block";
		},
		addAttributes: () => ({
			src: { default: null },
			alt: { default: null },
			title: { default: null },
		}),
		renderHTML: ({ HTMLAttributes }) => ["img", mergeAttributes(HTMLAttributes)],
		addInputRules() {
			return [
				nodeInputRule({
					find: IMAGE_INPUT_REGEX,
					type: this.type,
					getAttributes: (match) => {
						const [, , alt, src, title] = match;

						return { src, alt, title };
					},
				}),
			];
		},
		addProseMirrorPlugins() {
			return [uploadImagePlugin(uploadFn)];
		},
	});
};
