import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { getByLabel } from "@/domains/nodes/zustand/nodeMetaStore";

import { getNodeMetaObject } from "../../../../../modules/nodeMeta/utils/getNodeMetaValue";
import { generateColorHsl } from "../../../../../shared/system/Avatar/AvatarStatic";
import { Node, NodeMetaSystemLabels, NodeMetaValueSelect } from "../../../../../types/db";

export const useNodeHighlight = (node: Node) => {
	const nodeMetaStore = useNodeMetaStore();
	const nodeTypeOrError = getByLabel(NodeMetaSystemLabels.nodeType, nodeMetaStore);

	if (nodeTypeOrError.isFailure) {
		return;
	}

	const value = getNodeMetaObject(node, nodeTypeOrError.getValue()) as NodeMetaValueSelect | undefined;

	if (!value) {
		return generateColorHsl("yolo");
	}

	return generateColorHsl(value.title);
};
