const tagColors: string[] = [
	"#2ac67d", // Done
	"#2ac6c6",
	"#2aa5c6", // Now
	"#2a84c6",
	"#5826b4", // Next
	"#b42698",
	"#8826b4",
	"#b4264a",
	"#978108",
	"#b55626",
	"#f25454", // Later
];

export const getNextTagColor = (index: number): string => {
	// @ts-expect-error is possibly undefined
	return tagColors[index % tagColors.length];
};
