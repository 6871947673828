import { useShortcutsDepth } from "@/domains/nodes/components/menu/hooks/useShortcutsDepth";
import { useShortcutsFilters } from "@/domains/nodes/components/menu/hooks/useShortcutsFilters";
import { useShortcutsMeta } from "@/domains/nodes/components/menu/hooks/useShortcutsMeta";
import { useShortcutsNavigation } from "@/domains/nodes/components/menu/hooks/useShortcutsNavigation";
import { useShortcutsNodeAdd } from "@/domains/nodes/components/menu/hooks/useShortcutsNodeAdd";
import { useShortcutsNodeEdit } from "@/domains/nodes/components/menu/hooks/useShortcutsNodeEdit";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";

type Props = {
	node: NodeModel;
};

const Shortcuts: React.FC<Props> = ({ node }) => {
	useShortcutsNodeEdit(node);
	useShortcutsMeta(node);
	useShortcutsFilters(node);
	useShortcutsNodeAdd(node);
	useShortcutsNavigation(node);

	return null;
};

export const NodeKeyboardShortcuts = () => {
	useShortcutsDepth();

	const node = useNodesStore((state) => (state.focusedId ? state.nodes[state.focusedId] : null));

	if (!node) {
		return null;
	}

	return <Shortcuts node={node} />;
};
