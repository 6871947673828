import { useLayoutEffect } from "react";

import Mousetrap, { ExtendedKeyboardEvent } from "mousetrap";

import { useNodeFocus } from "@/domains/nodes/components/cards/hooks/useNodeFocus";
import { useNodeMetaStore } from "@/domains/nodes/hooks/useNodeMetaStore";
import { NodeAddPosition, useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { getByLabel } from "@/domains/nodes/zustand/nodeMetaStore";
import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { getNextNodeTypeId } from "@/modules/nodeMeta/utils/getNextNodeTypeId";
import { Result } from "@/shared/utils/result";
import { NodeMetaSelect, NodeMetaSystemLabels } from "@/types/db";

const findNextActiveId = (nodeId: string, parentNode: NodeModel) => {
	const children = parentNode?.children;
	if (!children || children.length <= 1) {
		return parentNode.id;
	}
	const currentIndex = children.indexOf(nodeId);
	return currentIndex === 0 ? children[1] : children[currentIndex - 1];
};

type UseShortcutNodeAdd = (node: NodeModel) => void;

export const useShortcutsNodeAdd: UseShortcutNodeAdd = (node) => {
	const treeService = useTreeService();
	const nodesService = useNodesService();
	const project = useGetProject();
	const { setFocused } = useNodeFocus();

	const nodeMetaStore = useNodeMetaStore();

	const nodes = useNodesStore((state) => state.nodes);
	const element = useNodesStore((state) => state.focusedEl?.current);

	const nodeId = node.id;

	const nodeTypesOrError = getByLabel(NodeMetaSystemLabels.nodeType, nodeMetaStore);
	const parentNodeOrError = treeService.getParent(nodeId);

	useLayoutEffect(() => {
		const results = Result.combine([parentNodeOrError, nodeTypesOrError]);

		if (!project || results.isFailure) {
			return;
		}

		const parent = parentNodeOrError.getValue();
		const nodesTypes = nodeTypesOrError.getValue() as NodeMetaSelect;

		const handleAddNode = (position?: NodeAddPosition) => (event: Mousetrap.ExtendedKeyboardEvent) => {
			event.preventDefault();

			const nextParent = position === undefined ? node : parent.data.document;

			nodesService.localAdd({
				parentId: nextParent.id,
				data: {
					meta: { [nodesTypes.id]: getNextNodeTypeId(nodesTypes, nextParent) },
				},
				position: {
					activeId: nodeId,
					position,
				},
			});
		};

		const handleDeleteNode = (event: ExtendedKeyboardEvent) => {
			event.preventDefault();
			nodesService.archive(nodeId);
			const nextActiveId = findNextActiveId(nodeId, parent.data.document);
			nextActiveId && setFocused(nextActiveId);
		};

		Mousetrap.bind("command+down", handleAddNode(undefined));
		Mousetrap.bind("command+right", handleAddNode("right"));
		Mousetrap.bind("command+left", handleAddNode("left"));
		Mousetrap.bind("command+backspace", handleDeleteNode);

		Mousetrap.bind("ctrl+down", handleAddNode(undefined));
		Mousetrap.bind("ctrl+right", handleAddNode("right"));
		Mousetrap.bind("ctrl+left", handleAddNode("left"));
		Mousetrap.bind("ctrl+backspace", handleDeleteNode);

		return () => {
			Mousetrap.unbind("command+down");
			Mousetrap.unbind("command+right");
			Mousetrap.unbind("command+left");
			Mousetrap.unbind("command+backspace");

			Mousetrap.unbind("ctrl+down");
			Mousetrap.unbind("ctrl+right");
			Mousetrap.unbind("ctrl+left");
			Mousetrap.unbind("ctrl+backspace");
		};
	}, [element, node, nodeId, nodeTypesOrError, nodes, nodesService, parentNodeOrError, project, setFocused, treeService]);
};
