import { useMemo } from "react";

import { useParams } from "react-router-dom";
import { ConnectionLineType, Edge, Node, Position } from "reactflow";

import { INodesStore, useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useTreeStore } from "@/domains/projects/hooks/useTreeStore";

const getHighlightedNodeIds = (nodes: INodesStore["nodes"]): string[] => {
	return Object.values(nodes)
		.filter((node) => node.highlighted)
		.map((node) => node.id);
};

interface INodesAndEdges {
	nodes: Node[];
	edges: Edge[];
}

const defaultState: INodesAndEdges = {
	nodes: [],
	edges: [],
};

export const useNodesAndEdges = (): INodesAndEdges => {
	const { boardId } = useParams();
	const root = useTreeStore((state) => state.treeFiltered);
	const treeService = useTreeService();
	const nodes = useNodesStore((state) => state.nodes);

	const highlightedNodesIds = useMemo(() => getHighlightedNodeIds(nodes), [nodes]);

	const nodesAndEdges = useMemo(() => {
		const highlightedPaths = treeService.getHighlightedPaths(highlightedNodesIds);

		if (!root || boardId !== root.data.document.board) {
			return defaultState;
		}

		const nodes: Node<NodeModel>[] = root.descendants().map((node) => {
			const { x, y, data } = node;

			// This is calculated as (source width - card width) / 2
			const sourceX = data.type === "source" ? x - 170 : null;
			const listItemX = data.type === "listItem" ? x - 121 : null;
			const listX = data.type === "list" ? x - 121 : null;

			const translatedX = sourceX ?? listItemX ?? listX ?? x;

			const className = highlightedPaths.has(data.id) ? "highlighted" : "";

			return {
				id: data.id as string,
				data: data.document,
				type: data.type,
				dragHandle: ".draggable",
				className,
				targetPosition: Position.Top,
				sourcePosition: Position.Bottom,
				zIndex: 2,
				position: {
					x: translatedX,
					y,
				},
			};
		});

		const excludeEdgeForType = ["listItem", "list"];

		const edges = root.links().reduce<Edge[]>((carry, link) => {
			const { source, target } = link;

			if (excludeEdgeForType.includes(source.data.type)) {
				return carry;
			}

			const stroke = highlightedPaths.has(target.data.id) ? "var(--color-accent)" : "var(--color-border)";
			const zIndex = highlightedPaths.has(target.data.id) ? 1 : 0;

			carry.push({
				id: `${source.data.id}-${target.data.id}`,
				source: source.data.id,
				target: target.data.id,
				type: ConnectionLineType.SmoothStep,
				zIndex,
				style: {
					strokeWidth: 2,
					stroke,
				},
			});

			return carry;
		}, []);

		return {
			nodes,
			edges,
		};
	}, [boardId, highlightedNodesIds, root, treeService]);

	return nodesAndEdges;
};
