import React, { useLayoutEffect } from "react";

import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Input } from "@/shared/system";

interface Props {
	value: string;
	disabled?: boolean;
	onUpdate: (args: { label: string; isDirty: boolean }) => void;
	close: VoidFunction;
}

export const NodeMetaSelectOptionsItemEditLabelInput = ({ value, disabled, onUpdate, close }: Props) => {
	const inputEl = React.useRef<HTMLInputElement>(null);
	const [label, setLabel] = React.useState<string>(value);

	const blur = () => {
		inputEl.current?.blur();
		close();
	};

	useBindShortcut("return", blur, inputEl);
	useBindShortcut("escape", close, inputEl);

	useLayoutEffect(() => {
		inputEl.current?.select();
	}, []);

	const handleSubmit = () => {
		onUpdate({ label, isDirty: label !== value });
	};

	const handleUpdateLabel = (e: React.SyntheticEvent<HTMLInputElement>) => {
		const value = e.currentTarget.value;

		setLabel(value);
	};

	return (
		<Input
			value={label}
			onChange={handleUpdateLabel}
			onBlur={handleSubmit}
			disabled={disabled}
			p="4px 8px"
			mb="0"
			ref={inputEl}
			data-testid="select-value-edit__input"
		/>
	);
};
