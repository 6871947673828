import { getMarkRange } from "@tiptap/core";
import LinkBase from "@tiptap/extension-link";
import { Plugin, TextSelection } from "prosemirror-state";

export const Link = LinkBase.extend({
	addProseMirrorPlugins() {
		return [
			new Plugin({
				props: {
					handleClick(view, pos) {
						// @ts-expect-error is possibly undefined
						const range = getMarkRange(view.state.doc.resolve(pos), view.state.schema.marks.link);
						if (!range) return false;

						const $start = view.state.doc.resolve(range.from);
						const $end = view.state.doc.resolve(range.to);
						const transaction = view.state.tr.setSelection(new TextSelection($start, $end));
						view.dispatch(transaction);

						return true;
					},
				},
			}),
		];
	},
});
