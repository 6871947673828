import { useCallback, useRef } from "react";

import { Handle, NodeProps, Position } from "reactflow";
import styled from "styled-components";

import { NodeActions } from "@/domains/nodes/components/actions/NodeActions";
import { NodeActionsCollapsed } from "@/domains/nodes/components/cards/components/NodeRevealCollapsed";
import { Node } from "@/domains/nodes/components/cards/Node";
import { useNodesService } from "@/domains/nodes/hooks/useNodesService";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { handleBottomStyle, handleTopStyle } from "@/domains/projects/components/tree/config/reactFlow";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";

export const TreeNodeCard: React.FC<NodeProps<NodeModel>> = ({
	data: node,
	dragging,
	isConnectable,
	targetPosition = Position.Top,
	sourcePosition = Position.Bottom,
}) => {
	const treeService = useTreeService();
	const nodesService = useNodesService();
	const elementRef = useRef<HTMLDivElement>(null);

	const nodeId = node.id;

	const isCollapsed = treeService.getIsNodeCollapsed(node.id);
	const descendantsCount = treeService.getDescendantCount(nodeId);

	const showActions = !dragging;

	const handleToggleCollapsed = useCallback(() => {
		nodesService.collapseNode(node.id);
	}, [node, nodesService]);

	return (
		<Root dragging={dragging} ref={elementRef} data-id={nodeId} data-layout={node.layout}>
			<Handle
				type="target"
				id={`target-${nodeId}`}
				position={targetPosition}
				isConnectable={isConnectable}
				style={handleTopStyle}
			/>
			<Node node={node} />
			{showActions && <NodeActions node={node} />}
			{isCollapsed && (
				<NodeActionsCollapsed layout={node.layout} childCount={descendantsCount} onClick={handleToggleCollapsed} />
			)}
			<Handle
				type="source"
				id={`source-${nodeId}`}
				position={sourcePosition}
				isConnectable={isConnectable}
				style={handleBottomStyle}
			/>
		</Root>
	);
};

const Root = styled.div<{ dragging: boolean }>`
	width: 260px;
`;
