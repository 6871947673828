import React from "react";

import { FiX } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useDetailsSectionVisibility } from "@/modules/board/hooks/useDetailsSectionVisibility";
import { NoteMore } from "@/modules/note/components/NoteMore";
import { CoverImage } from "@/modules/note/coverImage/CoverImage";
import { getParentPath } from "@/modules/note/utils/nodeNoteUtils";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Button } from "@/shared/system";

const HOTKEY_CLOSE_DETAILS = "escape";

interface Props {
	node: NodeModel;
	children?: React.ReactNode;
}

export const DetailsCard: React.FC<Props> = ({ node, children }) => {
	const { displayCover } = useDetailsSectionVisibility();
	const navigate = useNavigate();

	const boardId = node.board;
	const nodeCover = !!node.cover;

	const hasCover = displayCover && nodeCover;

	const handleClose = () => {
		navigate(`${getParentPath()}/${boardId}`);
	};

	useBindShortcut(HOTKEY_CLOSE_DETAILS, handleClose);

	return (
		<Card>
			<Header hasCover={hasCover}>
				<Left>
					{/* <HeaderAction>
							<FiMaximize2 /> <span>Open as page</span>
						</HeaderAction> */}
				</Left>
				<Right>
					<NoteMore node={node} />
					<HeaderAction onClick={handleClose}>
						<FiX />
					</HeaderAction>
				</Right>
			</Header>
			{displayCover && <CoverImage node={node} />}
			{children}
		</Card>
	);
};

const Card = styled.div`
	padding: 8px;
	overflow: auto;
`;

const Header = styled.div<{ hasCover: boolean }>`
	position: relative;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 12px 16px;
	width: 100%;

	${({ hasCover }) =>
		hasCover &&
		`
			 *  {
			color: #fff;
		}
	`}
`;

const HeaderAction = styled<any>(Button.Text)`
	padding: 4px 12px;
	color: var(--color-text-secondary);

	svg {
		font-size: 20px;
		margin-right: 4px;

		&:only-child {
			margin: 0;
		}
	}
`;

const Left = styled.div``;
const Right = styled.div`
	display: flex;

	> * {
		margin-left: 4px;
	}
`;
