import React, { useCallback, useRef } from "react";

import { FiX } from "react-icons/fi";
import styled from "styled-components";

import Button from "@/shared/system/Button/Button";

import { useBindShortcut } from "../../core/hooks/useBindShortcut";

type Props = {
	isOpen: boolean | null;
	isFixedHeight?: boolean;
	width?: string;
	padding?: string;
	actions?: JSX.Element;
	footer?: JSX.Element;
	withClose?: boolean;
	onClose: (isOpen: boolean) => void;
	children?: React.ReactNode;
};

export const Modal: React.FC<Props> = ({
	isOpen,
	isFixedHeight = false,
	width = "520px",
	padding = "24px",
	children,
	actions,
	footer,
	withClose = true,
	onClose,
}) => {
	const modalEl = useRef(null);

	const handleCloseModal = useCallback(() => {
		onClose(false);
	}, [onClose]);

	useBindShortcut("escape", handleCloseModal);

	return isOpen ? (
		<Root ref={modalEl}>
			<Overlay onClick={handleCloseModal} />
			<Wrapper isFixedHeight={isFixedHeight}>
				<Content width={width} padding={padding}>
					<Actions>
						{actions}
						{withClose && (
							<ModalHeaderAction onClick={handleCloseModal} aria-label="close modal">
								<FiX />
							</ModalHeaderAction>
						)}
					</Actions>
					{children}
				</Content>
				{footer && <Footer>{footer}</Footer>}
			</Wrapper>
		</Root>
	) : null;
};

export const ModalHeader = styled.h3`
	margin-top: 0;
	margin-bottom: 16px;

	span {
		color: var(--color-text);
	}
`;

const Actions = styled.div`
	position: absolute;
	top: 24px;
	right: 24px;
	display: flex;
	gap: 8px;
`;

export const ModalHeaderAction = styled(Button.Plain)`
	font-size: 18px;
	padding: 0;
	color: var(--color-text-heading);
`;

export const ModalSubHeader = styled.p`
	margin-top: -8px;
	margin-bottom: 20px;
`;

export const ModalActionGroup = styled.div`
	border-top: var(--border);
	padding: 16px 24px;
	margin: -24px;
	margin-top: 0;
	text-align: right;

	* + * {
		margin-left: 16px;
	}
`;

const Root = styled.div`
	position: fixed;
	z-index: 200;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
`;

export const Overlay = styled.div`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: var(--color-overlay);
`;

const Wrapper = styled.div<{ isFixedHeight: boolean }>`
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	flex-direction: column;
	max-width: 95%;
	height: ${({ isFixedHeight }) => (isFixedHeight ? "80vh" : "auto")};
	max-height: 80vh;
	background: var(--color-body);
	margin: 12vh auto 0;
	border-radius: 6px;
	box-shadow: var(--drop-shadow-l2);
`;

const Content = styled.div<{ width: string; padding: string }>`
	height: 100%;
	max-width: 100%;
	width: ${({ width }) => width};
	padding: ${({ padding }) => padding};
	overflow: auto;
`;

const Footer = styled.div``;
