import { useCallback, useEffect, useState } from "react";

import { defaultDropAnimationSideEffects, DragOverlay, DragStartEvent, DropAnimation, useDndMonitor } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { createPortal } from "react-dom";

import { SortableItem, SortableItemRender, SortableListItem } from "./SortableListItem";

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {},
	}),
};

type Props<TItem> = {
	items: TItem[];
	handle?: boolean;
	animate?: boolean;
	onDrop: (items: TItem[]) => void;
	renderItem: SortableItemRender<TItem>;
};

export function SortableListContainer<TItem extends SortableItem>({
	items: originalItems,
	renderItem,
	animate,
	onDrop = () => {},
	handle,
}: Props<TItem>) {
	const [activeId, setActiveId] = useState<string | null>(null);
	const [items, setItems] = useState(originalItems);

	useEffect(() => {
		setItems(originalItems);
	}, [originalItems]);

	const getIndex = useCallback((id: string) => items.findIndex((item) => item.id === id), [items]);
	const activeIndex = activeId ? getIndex(activeId) : -1;

	useDndMonitor({
		onDragStart({ active }: DragStartEvent) {
			if (!active) {
				return;
			}

			setActiveId(active.id.toString());
		},
		onDragEnd({ over, active }) {
			setActiveId(null);

			const isSameList = over?.data.current?.sortable?.containerId === active.data.current?.sortable?.containerId;

			if (!isSameList) {
				return;
			}

			if (over) {
				const overIndex = getIndex(over.id.toString());
				if (activeIndex !== overIndex) {
					setItems((items) => {
						const newItems = arrayMove(items, activeIndex, overIndex);
						onDrop(newItems);
						return newItems;
					});
				}
			}
		},
	});

	return (
		<>
			<SortableContext items={items} strategy={verticalListSortingStrategy}>
				{items.map((item, index) => {
					return (
						<SortableListItem
							id={item.id}
							key={item.id}
							renderItem={renderItem}
							index={index}
							item={item}
							handle={handle}
							animate={animate}
						/>
					);
				})}
			</SortableContext>
			{createPortal(
				<DragOverlay dropAnimation={dropAnimationConfig}>
					{/* @ts-expect-error is possibly undefined */}
					{activeId ? <div>{renderItem(items[activeIndex])} </div> : null}
				</DragOverlay>,
				document.body,
			)}
		</>
	);
}
