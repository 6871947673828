import { RefObject } from "react";

import produce, { enableMapSet } from "immer";
import { create } from "zustand";

import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { TreeModel, TreeNodeHeightMap } from "@/domains/projects/models/treeModel";
import { createSelectors } from "@/shared/zustand/createSelectors";

enableMapSet();

export type TreeStoreState = {
	id: string | null;
	nodeCollapsedIds: string[];
	nodeFocusedEl: RefObject<HTMLTextAreaElement> | null;
	nodeFocusedId: NodeModel["id"] | null;
	nodeHeights: TreeNodeHeightMap;
	tree: TreeModel | null;
	treeFiltered: TreeModel | null;
};

type TreeStoreActions = {
	setNodeHeight(nodeId: NodeModel["id"], height: number): void;
	setTree(id: string, tree: TreeModel): void;
	setTreeFiltered(tree: TreeModel): void;
	setNodeCollapsedIds(collapsedNodeIds: TreeStoreState["nodeCollapsedIds"]): void;
	setNodeFocused(nodeId: TreeStoreState["nodeFocusedId"]): void;
	setNodeFocusedEl(nodeId: TreeStoreState["nodeFocusedEl"]): void;
};

export type ITreeStore = TreeStoreState & TreeStoreActions;

export const store = create<ITreeStore>((set) => ({
	id: null,
	nodeCollapsedIds: [],
	nodeFocusedEl: null,
	nodeFocusedId: null,
	nodeHeights: new Map(),
	tree: null,
	treeFiltered: null,
	setTree: (id, tree) => set({ id, tree }),
	setTreeFiltered: (filteredTree) => set({ treeFiltered: filteredTree }),
	setNodeHeight(nodeId, height) {
		set((state) => ({
			nodeHeights: produce(state.nodeHeights, (draft) => {
				draft.set(nodeId, height);
			}),
		}));
	},
	setNodeCollapsedIds(nodeCollapsedIds) {
		set({ nodeCollapsedIds });
	},
	setNodeFocused(nodeFocusedId) {
		set({ nodeFocusedId });
	},
	setNodeFocusedEl(nodeFocusedEl) {
		set({ nodeFocusedEl });
	},
}));

export const treeStore = createSelectors(store);
