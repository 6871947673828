import { useLayoutEffect, useRef } from "react";

import styled from "styled-components";
import { space } from "styled-system";

// Lazy load script taken from web fundamental. https://web.dev/lazy-loading-video/
export function Video({ src, poster = "", width = "100%", height = "auto", ...rest }: any) {
	const videoRef = useRef(null);
	useLayoutEffect(() => {
		if ("IntersectionObserver" in window) {
			const lazyVideoObserver = new IntersectionObserver(function (entries) {
				entries.forEach(function (video) {
					if (video.isIntersecting) {
						for (const source in video.target.children) {
							const videoSource = video.target.children[source];
							// @ts-expect-error is possibly undefined
							if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
								(videoSource as any).src = (videoSource as any).dataset.src;
							}
						}
						(video.target as any).load();
						lazyVideoObserver.unobserve(video.target);
					}
				});
			});
			// @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
			lazyVideoObserver.observe(videoRef.current);
		}
	}, []);
	return (
		<StyledVideo
			playsInline
			autoPlay
			muted
			loop
			width={width}
			height={height}
			poster={poster}
			preload="none"
			ref={videoRef}
			{...rest}>
			<source data-src={src} type="video/mp4" />
			Your browser does not support the video tag.
		</StyledVideo>
	);
}
const StyledVideo = styled.video`
	${space}
	${({ theme }) => `
		border-radius: ${theme.radii[2]}px;
		overflow: hidden;
		box-shadow: ${theme.shadows.levelTwo};
	`}
`;
