/* eslint-disable no-console */
import { useMemo } from "react";

import { ErrorLogger } from "@/shared/infra/sentry/sentry";

type Logger = (message: string, context?: any) => void;

const ErrorAdaptor = process.env.VITE_SENTRY_KEY
	? ErrorLogger
	: {
			captureException: console.error,
			captureMessage: console.log,
		};

export interface IErrorService {
	logError: Logger;
}
export class ErrorSerivice implements IErrorService {
	logError: IErrorService["logError"] = (message, context) => {
		ErrorAdaptor.captureException(message, context, "error");

		if (context?.constructor.name === "_FirebaseError") {
			console.log("===========================");
			console.log(`${message}:`, context.code);
			console.log(context);
			console.trace();
			console.log("===========================");
		} else {
			console.log("===========================");
			console.log(message);
			console.log("context:", context);
			console.trace();
			console.log("===========================");
		}
	};
}

export const useErrorService = (): IErrorService => {
	return useMemo(() => new ErrorSerivice(), []);
};
