import { useEffect } from "react";

import { onSnapshot, query, where } from "firebase/firestore";
import { useRecoilState, useRecoilValue } from "recoil";

import { KeyResultsDto } from "@/domains/nodes/components/keyResults/dtos/keyResultsDto";
import { activeWorkspaceIdState } from "@/modules/authentication/hooks/useAuthenticationListener";
import { db, onSnapshotError } from "@/shared/infra/init";
import { extractFromCollectionWithId } from "@/shared/utils/dataUtils";

import { keyResultsState } from "../recoil/dataKeyResults";

// TODO: Currently gets all key results for workspace should limit scope to project here

export const useKeyResultsListener = () => {
	const workspaceId = useRecoilValue(activeWorkspaceIdState);
	const [, setKeyResults] = useRecoilState(keyResultsState);

	useEffect(() => {
		if (workspaceId) {
			const keyResultsQuery = query(db.keyResults, where("workspaceId", "==", workspaceId));
			return onSnapshot(
				keyResultsQuery,
				(snapshot) => {
					const collection = extractFromCollectionWithId<KeyResultsDto>(snapshot);
					setKeyResults(collection);
				},
				onSnapshotError("useKeyResultsListener"),
			);
		}
	}, [setKeyResults, workspaceId]);
};
