import { doc, DocumentReference, runTransaction, updateDoc } from "firebase/firestore";

import { nodeMetaService } from "@/domains/nodes/services";
import { projectCreateSystemFolders } from "@/modules/board/api/projectCreateSystemFolders";
import * as analyticsService from "@/shared/core/analytics/hooks/useAnalyticsService";
import { resetAllStores } from "@/shared/zustand/createStore";

import { db, fb } from "../../shared/infra/init";
import { Workspace, WorkspacePersonas } from "../../types/db";
import { createNodeMetaNodeTypeTemplate, createNodeMetaStatusTemplate } from "../nodeMeta/nodeMetaFunctions";
import nameGenerator from "./utils/nameGenerator";
import { nodeTypesGist } from "./workspaceTemplates";

// TODO: This is duplicated logic from both createAccount function
export async function createWorkspace(
	workspaceRef: DocumentReference<Workspace>,
	userId: string,
	title: string = nameGenerator(),
	overrides: Partial<Workspace> = {},
) {
	const workspaceDefault: Workspace = {
		title,
		archived: false,
		createdAt: fb.timestamp(),
		users: {
			allIds: [userId],
			byId: {
				[userId]: {
					status: "active",
					role: "admin",
					scope: "workspace",
				},
			},
		},
		...overrides,
	};

	try {
		const userRef = doc(db.users, userId);

		await runTransaction(fb.firestore, async (transaction) => {
			await transaction.set(workspaceRef, workspaceDefault);

			await transaction.update(userRef, {
				[`access.${workspaceRef.id}`]: {
					status: "active" as const,
					role: "admin" as const,
					scope: "workspace" as const,
				},
				activeWorkspaceId: workspaceRef.id,
			});
		});
	} catch (e) {
		return;
	}

	await projectCreateSystemFolders({
		workspaceId: workspaceRef.id,
		userId,
		isInvite: false,
	});

	analyticsService.accountCreated({
		workspace: { id: workspaceRef.id, ...workspaceDefault },
	});

	return workspaceRef.id;
}

export async function createWorkspaceWithNodeTypes(userId?: string, title: string = nameGenerator()) {
	if (!userId) {
		throw new Error("userId not defined");
	}

	resetAllStores();

	const workspaceRef = doc(db.workspaces);

	await createWorkspace(workspaceRef, userId, title);

	await nodeMetaService.createNodeMeta(workspaceRef.id, [
		createNodeMetaNodeTypeTemplate(nodeTypesGist),
		createNodeMetaStatusTemplate(),
	]);

	return workspaceRef.id;
}

export async function updateWorkspaceOnboardingStep(id: string, lastCompletedStep: number) {
	const workspaceReference = doc(db.workspaces, id);
	return updateDoc(workspaceReference, { "onboarding.step": lastCompletedStep });
}

export async function updateWorkspacePersona(workspaceId: string, persona: WorkspacePersonas) {
	const workspaceReference = doc(db.workspaces, workspaceId);
	return updateDoc(workspaceReference, { "onboarding.persona": persona });
}
