import { memo, useEffect } from "react";

import { useLocation } from "react-router-dom";

import { useAnalyticsService } from "@/shared/core/analytics/hooks/useAnalyticsService";

export const Analytics: React.FC = memo(() => {
	const location = useLocation();
	const analytics = useAnalyticsService();

	const pathname = location.pathname;

	useEffect(() => {
		analytics.page();
	}, [analytics, pathname]);

	return null;
});

Analytics.displayName = "Analytics";
