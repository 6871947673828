import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

import { NodeDetails } from "@/domains/nodes/components/details/NodeDetails";
import { NodeKeyboardShortcuts } from "@/domains/nodes/components/menu/NodeKeyboardShortcuts";
import { useNavigationShortcuts } from "@/modules/board/hooks/useNavigationShortcuts";
import routes from "@/shared/core/routes";

import { BoardContainer } from "./components/BoardContainer";
import { ProjectListeners } from "./components/BoardListeners";
import { TopBar } from "./topBar/TopBar";

type Props = {
	children?: React.ReactNode;
};

export const BoardAuthWrapper = ({ children }: Props) => {
	useNavigationShortcuts();

	const location = useLocation();
	const routeKey = location.pathname?.split("/")[3] ?? "";

	return (
		<ReactFlowProvider>
			<TopBar />
			<ProjectListeners />
			<NodeKeyboardShortcuts />
			<BoardContainer>{children}</BoardContainer>
			<AnimatePresence exitBeforeEnter>
				<Routes location={location} key={routeKey}>
					<Route path={routes.node} element={<NodeDetails />} />
				</Routes>
			</AnimatePresence>
		</ReactFlowProvider>
	);
};
