import { selector } from "recoil";

import { IWorkspaceProps } from "@/modules/authentication/utils/workspace";
import { WithId } from "@/types/db";

import { activeWorkspaceIdState } from "../../authentication/hooks/useAuthenticationListener";
import { workspacesState } from "./workspacesState";

export const getCurrentWorkspace = selector<WithId<IWorkspaceProps>>({
	key: "getCurrentWorkspace",
	get: ({ get }) => {
		const workspaces = get(workspacesState);
		const workspaceId = get(activeWorkspaceIdState);
		return workspaceId && workspaces[workspaceId] ? workspaces[workspaceId] : Object.values(workspaces)[0]!;
	},
});
