import Mousetrap from "mousetrap";

export class MenuUtils {
	public static unbindShortcut(shortcut: string | string[]) {
		try {
			Mousetrap.unbind(shortcut);
		} catch (e) {
			console.error("Unable to unbind keyboard shortcut", shortcut);
		}
	}
}
