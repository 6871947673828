import { Node, NodeMetaSelect } from "../../../types/db";

export const getNextNodeTypeId = ({ id, value }: NodeMetaSelect, node?: Node): string => {
	const metaId = node?.meta;
	// @ts-expect-error Checks for the legacy 'nodeType' property as well
	const legacyNodeType = node?.nodeType as string;

	const currentId = metaId ? (metaId[id] as string) : legacyNodeType;

	const nodeTypeIds = value.map((nodeType) => nodeType.id);
	const currentNodeTypeIndex = nodeTypeIds.indexOf(currentId);
	const lastIndex = nodeTypeIds.length - 1;

	if (!currentId) {
		// @ts-expect-error is possibly undefined
		return nodeTypeIds[0];
	}

	if (currentNodeTypeIndex !== lastIndex) {
		// @ts-expect-error is possibly undefined
		return nodeTypeIds[currentNodeTypeIndex + 1];
	}

	return currentId;
};
