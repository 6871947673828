import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";
import { usePermissions } from "@/modules/workspace/hooks/usePermissions";

const voidFunction = () => {};

export const useNodeFocus = () => {
	const { canEdit } = usePermissions("project");

	const focusedId = useNodesStore.use.focusedId();
	const setFocused = useNodesStore.use.setFocused();

	return { focusedId, setFocused: canEdit ? setFocused : voidFunction };
};
