import { useCallback, useState } from "react";

import { useGetProject } from "@/domains/projects/hooks/useGetProject";
import { ShareModal } from "@/modules/board/share/components/ShareModal";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Button } from "@/shared/system";

const HOTKEY_SHARE = "p";

export const Share = () => {
	const project = useGetProject();
	const [isModalOpen, setModalState] = useState(false);

	const handleModalOpen = useCallback((e: any) => {
		e.preventDefault();
		setModalState(true);
	}, []);

	useBindShortcut(HOTKEY_SHARE, handleModalOpen);

	if (!project) {
		return null;
	}

	return (
		<>
			<Button onClick={handleModalOpen} rounded>
				Share
			</Button>
			<ShareModal project={project} open={isModalOpen} setOpen={setModalState} />
		</>
	);
};
