import { useNodeFocus } from "@/domains/nodes/components/cards/hooks/useNodeFocus";
import { NodeModel } from "@/domains/nodes/models/nodesModel";
import { useTreeService } from "@/domains/projects/hooks/useTreeService";
import { useBindShortcut } from "@/shared/core/hooks/useBindShortcut";
import { Result } from "@/shared/utils/result";

type UseShortcutsNavigation = (node: NodeModel) => void;

export const useShortcutsNavigation: UseShortcutsNavigation = (node) => {
	const treeService = useTreeService();
	const { setFocused } = useNodeFocus();

	const nodeId = node.id;

	const handleFocusLeft = () => {
		const siblingsOrError = treeService.getSiblings(nodeId);
		const parentOrError = treeService.getParent(nodeId);

		const result = Result.combine([siblingsOrError, parentOrError]);

		if (result.isFailure) {
			return null;
		}

		const siblings = siblingsOrError.getValue();
		const siblingIndex = siblings.findIndex(({ data }) => data.id === nodeId);

		if (siblingIndex > 0) {
			const leftSibling = siblings[siblingIndex - 1]?.data.id || null;
			setFocused(leftSibling);

			return null;
		} else {
			const parentId = parentOrError.getValue().data.id;

			const parentSiblingsOrError = treeService.getSiblings(parentId);

			if (parentSiblingsOrError.isFailure) {
				return null;
			}

			const parentSiblings = parentSiblingsOrError.getValue();
			const parentSiblingIndex = parentSiblings.findIndex(({ data }) => data.id === parentId);

			if (parentSiblingIndex > 0) {
				const leftSibling = parentSiblings[parentSiblingIndex - 1]?.data.id || "";
				const isCollapsed = treeService.getIsNodeCollapsed(leftSibling);

				if (!leftSibling || isCollapsed) {
					return null;
				}

				const leftCousinsOrError = treeService.getChildren(leftSibling);

				if (leftCousinsOrError.isFailure) {
					console.warn(leftCousinsOrError.getErrorValue());
					return null;
				}

				const leftCousins = leftCousinsOrError.getValue();
				const leftCousin = leftCousins[leftCousins.length - 1]?.data.id || null;

				setFocused(leftCousin);
			}
		}
	};

	useBindShortcut("left", handleFocusLeft);

	const handleFocusRight = () => {
		const siblingIdsOrError = treeService.getSiblings(nodeId);
		const parentOrError = treeService.getParent(nodeId);

		const result = Result.combine([siblingIdsOrError, parentOrError]);

		if (result.isFailure) {
			return null;
		}

		const siblingIds = siblingIdsOrError.getValue();

		const siblingIndex = siblingIds.findIndex(({ data }) => data.id === nodeId);

		if (siblingIndex < siblingIds.length - 1) {
			const leftSibling = siblingIds[siblingIndex + 1]?.data.id || null;
			setFocused(leftSibling);
		} else {
			const parentId = parentOrError.getValue().data.id;

			const parentSiblingsOrError = treeService.getSiblings(parentId);

			if (parentSiblingsOrError.isFailure) {
				return null;
			}

			const parentSiblingIds = parentSiblingsOrError.getValue();
			const parentSiblingIndex = parentSiblingIds.findIndex(({ data }) => data.id === parentId);

			if (parentSiblingIndex < parentSiblingIds.length - 1) {
				const rightSibling = parentSiblingIds[parentSiblingIndex + 1]?.data.id || "";
				const isCollapsed = treeService.getIsNodeCollapsed(rightSibling);

				if (!rightSibling || isCollapsed) {
					return null;
				}

				const rightCousinOrError = treeService.getChildren(rightSibling);

				if (rightCousinOrError.isFailure) {
					return null;
				}

				const rightCousin = rightCousinOrError.getValue()[0]?.data.id || null;

				setFocused(rightCousin);
			}
		}
	};

	useBindShortcut("right", handleFocusRight);

	const handleFocusDown = () => {
		const childIdsOrError = treeService.getChildren(nodeId);

		if (childIdsOrError.isFailure) {
			return null;
		}

		const childIds = childIdsOrError.getValue();

		const isCollapsed = treeService.getIsNodeCollapsed(nodeId);

		if (!isCollapsed && childIds.length > 0) {
			const firstChild = childIds[0]?.data.id || null;
			setFocused(firstChild);
		}
	};

	useBindShortcut("down", handleFocusDown);

	const handleFocusUp = () => {
		const parentOrError = treeService.getParent(nodeId);

		if (parentOrError.isFailure) {
			return null;
		}

		const parent = parentOrError.getValue();

		if (parent) {
			setFocused(parent.data.id);
		}
	};

	useBindShortcut("up", handleFocusUp);
};
