import { RefObject } from "react";

import produce from "immer";
import { create } from "zustand";

import { LocalNodeState, NodeModel, NodeStore } from "@/domains/nodes/models/nodesModel";

// Could this be commited on every change and t
const _mergeLocalNodeData = (nodes: NodeStore, local: LocalNodeState): NodeStore => {
	return produce(nodes, (draft) => {
		switch (local?.type) {
			case "create": {
				const node = local.data.node;
				const parent = local.data.parent;
				draft[node.id] = { ...node };
				draft[node.id]!.creating = false;
				draft[parent.id]!.children = parent.children;
				break;
			}
			case "update": {
				const node = local.data.node;
				draft[node.id] = { ...node };
				draft[node.id]!.creating = false;
				break;
			}
			case "reorder": {
				const outgoing = local.data.outgoing;
				const incoming = local.data.incoming;
				draft[outgoing.id]!.children = outgoing.children;
				draft[incoming.id]!.children = incoming.children;
				break;
			}
			default: {
				break;
			}
		}
	});
};

type State = {
	local: LocalNodeState;
	isLoading: boolean;
	nodes: NodeStore;
	focusedEl: RefObject<HTMLTextAreaElement> | null;
	focusedId: NodeModel["id"] | null;
};

type Actions = {
	setChildren(nodeId: NodeModel["id"], children: NodeModel["children"]): void;
	setFocused(nodeId: State["focusedId"]): void;
	setFocusedEl(nodeId: State["focusedEl"]): void;
	setLocal(local: State["local"]): void;
	setMergeLocal(local: State["local"]): void;
	setNodeMeta(nodeId: NodeModel["id"], metaId: string, value: any): void;
	setData(nodes: NodeStore): void;
	setIsLoading(isLoading: boolean): void;
};

export type INodesStore = State & Actions;

export const nodesStore = create<INodesStore>((set, get) => ({
	nodes: {},
	isLoading: true,
	focusedId: null,
	focusedEl: null,
	local: null,
	setChildren(nodeId, children) {
		const state = get();
		const nodes = produce(state.nodes, (draft) => {
			if (draft[nodeId]) {
				draft[nodeId].children = children;
			}
		});

		set({ nodes });
	},
	setFocused(nodeId) {
		const isEditing = get().local !== null;

		if (!isEditing) {
			set({ focusedId: nodeId });
		}
	},
	setFocusedEl(element) {
		set({ focusedEl: element });
	},
	setIsLoading(isLoading) {
		set({ isLoading });
	},
	setLocal(local) {
		set({ local });
	},
	setMergeLocal(local) {
		const { nodes } = get();
		const nextNodes = _mergeLocalNodeData(nodes, local);

		return set({ nodes: nextNodes, local: null, focusedId: null });
	},
	setNodeMeta(nodeId, metaId, value) {
		const prevNodes = get().nodes;
		const nodes = produce(prevNodes, (draft) => {
			const node = draft[nodeId];
			if (node && node.meta) {
				node.meta[metaId] = value;
			}
		});

		set({ nodes });
	},
	setData(nodes) {
		set({ isLoading: false, nodes });
	},
}));

export const getNodesForProject = (projectId: string, nodes: INodesStore["nodes"]) => {
	const nodesArray = Object.values(nodes);
	return nodesArray.filter((node) => node.board === projectId);
};

export const getNodesWithDrafts = (nodes: NodeStore, local: LocalNodeState) => {
	let nodesWithDrafts = _mergeLocalNodeData(nodes, local);

	if (local?.type === "create" || local?.type === "update") {
		nodesWithDrafts = produce(nodesWithDrafts, (draft) => {
			draft[local.data.node.id]!.creating = true;
		});
	}

	return nodesWithDrafts;
};
