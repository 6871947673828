import { Unsubscribe } from "firebase/firestore";

import { NodeMetaModel } from "@/domains/nodes/models/nodeMetaModel";
import { nodeMetaService } from "@/domains/nodes/services";
import { Result } from "@/shared/utils/result";
import { createStore } from "@/shared/zustand/createStore";
import { NodeMetaAllTypes } from "@/types/db";

type State = {
	id: string;
	isLoading: boolean;
	meta: NodeMetaModel;
};

type Actions = {
	connectNodeMeta(metaId: string): Unsubscribe;
	setIsLoading(isLoading: boolean): void;
};

export type NodeMetaStore = State & Actions;

export const nodeMetaStore = createStore<NodeMetaStore>((set) => ({
	id: "",
	isLoading: true,
	meta: new Map(),
	setIsLoading: (isLoading) => set({ isLoading }),
	connectNodeMeta: (documentId) => nodeMetaService.listen(documentId, (data) => set({ isLoading: false, ...data })),
}));

export const getByLabel = (label: string, state: NodeMetaStore): Result<NodeMetaAllTypes> => {
	const meta = state.meta;
	const metaArray = [...meta.values()];
	const matchedMeta = metaArray.find((meta) => meta.label === label);

	if (matchedMeta === undefined) {
		return Result.fail("Unable to find meta for label");
	}

	return Result.ok(matchedMeta);
};

export const getById = (nodeMetaId: string, state: NodeMetaStore): Result<NodeMetaAllTypes> => {
	const meta = state.meta.get(nodeMetaId);

	if (meta === undefined) {
		return Result.fail("Unable to find node meta for id");
	}

	return Result.ok(meta);
};

export const getAllOfType = <T extends NodeMetaAllTypes>(type: T["type"], meta: NodeMetaStore["meta"]): Result<T[]> => {
	const metaArray = [...meta.values()];

	const matchedMeta = metaArray.filter((meta): meta is T => meta.type === type);

	if (matchedMeta === undefined) {
		return Result.fail("Unable to find meta for type");
	}

	return Result.ok(matchedMeta);
};
