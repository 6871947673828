import { useEffect } from "react";

import { useNodesRepository } from "@/domains/nodes/hooks/useNodesRepository";
import { useNodesStore } from "@/domains/nodes/hooks/useNodesStore";

/**
 * Currently this is slightly more memoized than using the nodesService
 * this should be explored.
 *
 * When getting nodes this fetches (1) where as the services logs (2) requests on load
 */
export const useNodesListener = (projectId: string) => {
	const nodesRepository = useNodesRepository();

	const setData = useNodesStore((state) => state.setData);
	const setLoading = useNodesStore((state) => state.setIsLoading);

	useEffect(() => {
		setLoading(true);

		return nodesRepository.listen(projectId, setData);
	}, [projectId, nodesRepository, setData, setLoading]);
};
