import * as Accordion from "@radix-ui/react-accordion";
import { VscTriangleDown } from "react-icons/vsc";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import { AccordionContent, AccordionTrigger } from "@/shared/system/Accordion";

import { importState } from "../hooks/useImportListener";
import { MoreImportFile } from "./MoreImportFile";

type Props = {
	boardId: string;
};

export const MoreImportFiles = ({ boardId }: Props) => {
	const files = useRecoilValue(importState(boardId));

	if (files.length === 0) {
		return null;
	}

	return (
		<Accordion.Root type="single" defaultValue="item-1" collapsible>
			<Item value="item-1">
				<AccordionTrigger>
					<VscTriangleDown className="caret" />
					<span>Last import</span>
				</AccordionTrigger>
				<AccordionContent>
					{" "}
					{files.map((file) => (
						<MoreImportFile file={file} key={file.id} />
					))}{" "}
				</AccordionContent>
			</Item>
		</Accordion.Root>
	);
};

const Item = styled(Accordion.Item)`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
