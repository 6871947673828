import { Tree } from "@/domains/projects/components/tree/Tree";
import { BoardAuthWrapper } from "@/modules/board/BoardAuthWrapper";

const TreeViewPage = () => {
	return (
		<BoardAuthWrapper>
			<Tree />
		</BoardAuthWrapper>
	);
};

export default TreeViewPage;
